import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import SvgButton from '../../../common/components/SvgButton';
import Popup from '../../../common/components/Popup';

import { getSubarticleFullNumber } from './ArticlesUtils';

import lang from "../../../common/lang/de.json";

import {
    removeArticleFromContract,
    removeNotSavedSubarticleFromContract,
    removeArticleNotAvailable
} from '../../store/reservationSlice';

const DeleteArticleButton = (props) => {
    const dispatch = useDispatch();

    const [showPopup, setShowPopup] = useState(false);

    const { article, subarticle } = props;
    const isSubarticle = !!subarticle;

    let articleNumber = article.gnr;
    if(isSubarticle) {
        articleNumber = getSubarticleFullNumber(articleNumber, subarticle.prefix, subarticle.number);
    }

    const onDeleteArticleConfirmed = () => {
        setShowPopup(false);

        let subarticleId = 0;
        let recurringId = article.recurring_id;
        let recurrencePos = article.recurrence_pos;

        if(isSubarticle) {
            subarticleId = subarticle.subarticle_id;
            recurringId = subarticle.recurring_id;
            recurrencePos = subarticle.recurrence_pos;
        }

        // ANDY - 14.07.2022 - remove the article from unavailable articles array when deleted(mark as available)
        dispatch(removeArticleNotAvailable({
            "articleId": article.a_id,
            "subarticleId": (isSubarticle ? subarticle.subarticle_id : 0)
        }));

        // If this is a subarticle that has not been saved on the server just remove it from the state
        if(isSubarticle && subarticle.not_saved) {
            dispatch(removeNotSavedSubarticleFromContract({
                article_id: article.a_id,
                subarticle_id: subarticleId,
                recurring_id: recurringId,
                recurring_pos: recurrencePos
            }));
        } else {
            dispatch(removeArticleFromContract({
                article_id: article.a_id,
                subarticle_id: subarticleId,
                recurring_id: recurringId,
                recurring_pos: recurrencePos
            }));
        }
    }

    return (
        <>
            <div className="delete_article">
                <SvgButton onClick={() => setShowPopup(true)} svgRef="icon-x" textRight="" />
            </div>

            {showPopup &&
                <Popup title={lang.reservation.remove_article} okButtonLabel={lang.reservation.remove_article} okButtonClass="red"
                    onOkClicked={onDeleteArticleConfirmed} onCancelClicked={() => setShowPopup(false)}>

                    <span>{lang.reservation.confirm_remove_article}</span>
                    <br />
                    <b>{articleNumber}</b>
                </Popup>
            }
        </>
    );
}

DeleteArticleButton.propTypes = {
    article: PropTypes.object.isRequired,
    subarticle: PropTypes.object
}

export default DeleteArticleButton;