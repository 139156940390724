
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import SubarticlesPopup from './SubarticlesPopup';
import SvgButton from '../../../common/components/SvgButton';
import lang from "../../../common/lang/de.json"

import {
    addNewArticle
} from '../../store/reservationSlice';

const AddSubarticleButton = (props) => {
    const { article, more_subarticles_available } = props;

    const dispatch = useDispatch();

    const [showPopup, setShowPopup] = useState(false);

    if(article.subarticles.length == 0) {
        return <></>;
    }

    const onAddSubarticle = (selectedSubarticleId, fromDate, toDate) => {
        const subarticles_already_in_contract = article.subarticles.map(sa => sa.subarticle_id);

        dispatch(addNewArticle({article_id: article.a_id, 
            from: fromDate, to: toDate, 
            subarticles_already_in_contract,
            available_subarticles: [selectedSubarticleId]}));
    }

    const onAddSubarticleConfirmed = (selectedSubarticleId, fromDate, toDate) => {
        setShowPopup(false);

        onAddSubarticle(selectedSubarticleId, fromDate, toDate);
    }

    const lastSubarticle = article.subarticles[article.subarticles.length - 1];

    return (
        <div className='article'>
            <div className="description"></div>
            
            <div className="add_subarticles">
                {more_subarticles_available
                    ? <SvgButton svgRef="icon-add" textRight={lang.reservation.add_subarticle} onClick={() => setShowPopup(true)} />
                    : <span>{lang.reservation.subarticles_not_available}</span>
                }
            </div>

            {showPopup &&
                <SubarticlesPopup title={lang.reservation.replace_add_subarticle_popup.add_subarticle_title}
                    article={article} subarticle={lastSubarticle}
                    // these are empty, => the subarticle from/to dates will be used
                    lastUsedFromInAvailabilityCheck={""}
                    lastUsedToInAvailabilityCheck={""}
                    showOkButton={false} onActionConfirmed={onAddSubarticleConfirmed} onCancel={() => setShowPopup(false)} />
            }

        </div>

    );
}

AddSubarticleButton.propTypes = {
    article: PropTypes.object.isRequired,
    more_subarticles_available: PropTypes.bool.isRequired
}

export default AddSubarticleButton;
