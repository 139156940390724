import React from 'react';
import { useSelector } from 'react-redux';

import QVDataTable from '../../common/components/QVDataTable';

import { shopSearchTermsStatisticsTableColumns } from './ShopSearchTermsStatisticsConfig'

import {
    selectShopSearchTermsStatisticsData,
    selectIsLoading
} from './store/shopSearchTermsStatisticsSlice';

const ShopSearchTermsStatisticsTable = () => {

    const shopSearchTermsStatisticsData = useSelector(selectShopSearchTermsStatisticsData);
    const isLoading = useSelector(selectIsLoading);

    return (
        <QVDataTable
            title=""
            columns={shopSearchTermsStatisticsTableColumns}
            data={shopSearchTermsStatisticsData}
            isLoading={isLoading}
            noDataToShowText=""
            pagination={false}
        />
    );
}

export default ShopSearchTermsStatisticsTable;