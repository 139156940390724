import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import SettingsPriceApp from './SettingsPriceApp';

import settingsPriceReducer from './store/settingsPriceSlice';

// the interface between js app and react app
window.quickverleih.settings_prices_app = (function() {

    function init() {

        let settingsPriceStore = configureStore({
            reducer: {
                settings_price: settingsPriceReducer,
            }
        })

        ReactDOM.render(
            <Provider store={settingsPriceStore}>
                <SettingsPriceApp />
            </Provider>,
            document.getElementById("settings_prices_app")
        );
    }

    return {
        init
    };
})();