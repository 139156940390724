import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from "classnames";
import ReactTooltip from 'react-tooltip';
import DOMPurify from 'dompurify';

import FromToInterval from "./FromToInterval";
import CountAndHandedOutSection from "./CountAndHandedOutSection";
import ReturnsSection from "./ReturnsSection";
import DeleteArticleButton from './DeleteArticleButton';
import SvgButton from '../../../common/components/SvgButton';
import ReplaceSubarticleButton from './ReplaceSubarticleButton';
import attachmentLink from "../../AttachmentLink";
import CheckBox from '../../../common/components/CheckBox';

import ArticleAvailabilityApi from "../../api/ArticleAvailabilityApi";

import { getHandedoutCount, getReturnedCount } from "./HandoutsReturnsUtils";

import { getSubarticleFullNumber } from './ArticlesUtils';

import lang from "../../../common/lang/de.json";

import { STATUS_RESERVED, STATUS_HANDEDOUT, STATUS_RETURNED } from "../ArticleStatus";

import {
    selectReservationId,
    selectIsReservation,
    setArticleDates,
    setArticleCount,
    addArticleNotAvailable,
    removeArticleNotAvailable,
    getArticleAvailabilityById,
    selectReservationHeader,
    selectSelectedArticles,
    setSelectedArticle,
    selectStartEndDateForAllArticles
} from '../../store/reservationSlice';

import { convertDateForServer } from '../../../common/utils';

const getInfoForUI = (article, subarticle, isFirst) => {
    let shouldDisplayImgAndName = true,
        imgUrl = article.img_url,
        articleId = article.a_id,
        subarticleId = 0,
        articleName = article.a_name,
        number = article.gnr,
        showSpeziTooltip = false,
        status = article.in_contract_status,
        ui_status = lang.reservation.article_status[article.in_contract_status],
        from = article.a_from,
        to = article.a_to,
        count = article.a_menge,
        recurringId = article.recurring_id,
        recurrencePos = article.recurrence_pos,
        handouts = article.handouts,
        returns = article.returns,
        attachment = article.attachment,
        articleStateText = false,
        incoming = article.incoming == "1" ? true : false,
        overdue = article.overdue == "1" ? true : false,
        locationName = article.locationName;

    if(article.del == 1) {
        articleStateText = lang.article_state.deleted;
    } else if(article.available == 0) {
        articleStateText = lang.article_state.inactive;
    }
    if(subarticle) {
        // only the first sub displays the article name and image
        shouldDisplayImgAndName = isFirst;
        subarticleId = subarticle.subarticle_id;
        number = getSubarticleFullNumber(number, subarticle.prefix, subarticle.number);
        showSpeziTooltip = subarticle.spezi && subarticle.spezi.length > 0;
        status = subarticle.in_contract_status;
        ui_status = lang.reservation.article_status[subarticle.in_contract_status];
        from = subarticle.start_date;
        to = subarticle.end_date;
        count = 1;
        recurringId = subarticle.recurring_id;
        recurrencePos = subarticle.recurrence_pos;
        handouts = subarticle.handouts;
        returns = subarticle.returns;
        incoming = subarticle.incoming == "1" ? true : false;
        overdue = subarticle.overdue == "1" ? true : false;
        locationName = subarticle.locationName;

        if(subarticle.del == 1) {
            articleStateText = lang.article_state.deleted;
        } else if(subarticle.is_active == 0) {
            articleStateText = lang.article_state.inactive;
        }
    }

    return {shouldDisplayImgAndName, imgUrl, articleId, subarticleId, articleName, number, showSpeziTooltip, status, ui_status, from, to, count, recurringId, recurrencePos, handouts, returns, articleStateText, incoming, overdue, attachment, locationName};
}

const ArticlesTableEntry = (props) => {
    const dispatch = useDispatch();
    const pdfUrlPrefix = "article";

    const [ lastUsedFromInAvailabilityCheck, setLastUsedFromInAvailabilityCheck ] = useState('');
    const [ lastUsedToInAvailabilityCheck, setLastUsedToInAvailabilityCheck ] = useState('');

    const { article, subarticle, first } = props;
    const isSubarticle = !!subarticle;

    const reservationId = useSelector(selectReservationId);
    const isReservation = useSelector(selectIsReservation);
    const reservationHeader = useSelector(selectReservationHeader);

    const { shouldDisplayImgAndName, imgUrl, articleId, subarticleId, articleName, number, showSpeziTooltip, status, ui_status, from, to, count, recurringId, recurrencePos, handouts, returns, articleStateText, incoming, overdue, attachment, locationName } = getInfoForUI(article, subarticle, first);

    const selectedArticles = useSelector(selectSelectedArticles);
    const articleSubarticleId = `${articleId}_${subarticleId}`;
    const isSelected = selectedArticles.includes(articleSubarticleId);

    const {startDateForAllArticles, endDateForAllArticles} = useSelector(selectStartEndDateForAllArticles);

    const isAvailable = useSelector(getArticleAvailabilityById(articleId, subarticleId));
    const checkSubarticleAvailability = async (from, to) => {
        setLastUsedFromInAvailabilityCheck(from);
        setLastUsedToInAvailabilityCheck(to);

        const response = await ArticleAvailabilityApi.checkSubarticle(reservationId, from, to, subarticle.subarticle_id, article.days_block_before, article.days_block_after);
        return response.success && response.is_subarticle_available;
    }

    const checkAvailability = async (from, to, count) => {
        setLastUsedFromInAvailabilityCheck(from);
        setLastUsedToInAvailabilityCheck(to);

        const response = await ArticleAvailabilityApi.check(reservationId, from, to, article.a_id, count, article.days_block_before, article.days_block_after);
        return response.success && response.is_available;
    }

    useEffect(() => {
        // check if dates are valid and if article is selected
        if(isSelected && startDateForAllArticles && endDateForAllArticles &&
            startDateForAllArticles != "" && endDateForAllArticles != "") {

            const isArticleAvailable = onTimeIntervalChanged(startDateForAllArticles, endDateForAllArticles);
            if(!isArticleAvailable) {
                // todo: set the dates
                // in this case for the from to interval
                // normally when the user selects a date, the date is selected within interval
            }
        }
    }, [startDateForAllArticles, endDateForAllArticles]);

    const onTimeIntervalChanged = async (from, to) => {
        let isArticleAvailable = true;

        if(isSubarticle) {
            isArticleAvailable = await checkSubarticleAvailability(from, to);
        } else {
            isArticleAvailable = await checkAvailability(from, to, article.a_menge);
        }

        if(isArticleAvailable) {
            dispatch(removeArticleNotAvailable({
                articleId,
                subarticleId
            }));

            dispatch(setArticleDates({
                article_id: articleId,
                subarticle_id: subarticleId,
                from: from,
                to: to,
                recurring_id: recurringId,
                recurrence_pos: recurrencePos
            }));
        } else {
            dispatch(addArticleNotAvailable({
                articleId,
                subarticleId
            }));
        }

        return isArticleAvailable;
    }

    const onCountChange = async (count) => {
        const from = convertDateForServer(article.a_from);
        const to = convertDateForServer(article.a_to);

        const isArticleAvailable = await checkAvailability(from, to, count);

        if(isArticleAvailable) {
            dispatch(removeArticleNotAvailable({
                articleId,
                subarticleId
            }));
            dispatch(setArticleCount({
                article_id: article.a_id,
                new_count: count,
                recurring_id: article.recurring_id,
                recurrence_pos: article.recurrence_pos
            }));
        } else {
            dispatch(addArticleNotAvailable({
                articleId,
                subarticleId
            }));
        }

        return isArticleAvailable;
    }

    const handleOpenAttachment = (attachment) => {
        window.open(attachmentLink(pdfUrlPrefix, attachment), '_blank').focus();
    }

    const renderSpeziTooltip = (spezis) => {
        let i = 1;
        let speziToolTip = [];
        
        for(var spezi of spezis) {
            speziToolTip.push(
                <div key={'spezi_'+i} >
                    <div className='sub-title' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(spezi.attr) }} />
                    <div className='data' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(spezi.spezi) }} />
                </div>
            );

            i++;
        }

        return speziToolTip;
    };

    const handleArticleSelected = () => {
        dispatch(setSelectedArticle({article_subarticle_id: articleSubarticleId, selected: !isSelected}));
    }

    // on request to add new subarticle to contract, it can happen that no subarticles are available for the requested time interval, but not all subarticles are yet in the contract
    // in this case a subarticle is returned from the server and displayed as not available
    const isNotSavedSubarticle = subarticle && subarticle.not_saved;
    const cssClasses = classNames("article", {not_available: (!isAvailable || isNotSavedSubarticle)});
    const statusCssClasses = classNames("status", {green: status == STATUS_HANDEDOUT || status == STATUS_RETURNED});
    // Andy: check merge - check with Raul if this conditions are enough for marking the article read-only
    const isReadOnly = status == STATUS_RETURNED || reservationHeader.status != 1;

    const handedOutCount = getHandedoutCount(handouts);
    const returnedCount = getReturnedCount(returns);

    const idForTooltip = "spezi_tooltip_" + subarticleId;

    return (
        <div className={cssClasses}>
            <div className="description">
                {shouldDisplayImgAndName &&
                    <>
                        <img src={imgUrl} />
                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(articleName) }} />
                        { attachment && attachment != "" &&<SvgButton svgRef="icon-download" textRight="" onClick={() => handleOpenAttachment(attachment)} /> }
                    </>
                }
            </div>
            <div className="checkbox_action">
                <CheckBox htmlIdName={`checkbox_select_article_${articleSubarticleId}`} text={""} checked={isSelected} onChange={handleArticleSelected}
                    style={{width: "25px"}} />
            </div>
            <div className="number">
                <div className='number_and_stat'>
                    <span>{number}</span>
                    <span className='location_name'>{locationName}</span>
                    { articleStateText && 
                        <span className='color_red'>{articleStateText}</span>
                    }
                </div>
                
                <div className='number_icons'>
                    { showSpeziTooltip && 
                        <svg data-tip='' data-for={idForTooltip} className="svg_icon">
                            <use xlinkHref={"#icon-info"}></use>
                        </svg>
                    }

                    {isSubarticle && subarticle.in_contract_status == STATUS_RESERVED &&
                    <ReplaceSubarticleButton article={props.article} subarticle={props.subarticle}
                        lastUsedFromInAvailabilityCheck={lastUsedFromInAvailabilityCheck}
                        lastUsedToInAvailabilityCheck={lastUsedToInAvailabilityCheck} />
                    }
                </div>
            </div>
            {!incoming && overdue &&
                <div className="status overdue"><span>ÜBERFÄLLIG</span></div>}
            {incoming && !overdue &&
                <div className="status incoming"><span>ANSTEHEND</span></div>}
            {!incoming && !overdue &&
                <div className={statusCssClasses}>{ui_status}</div>}

            <FromToInterval article_id={articleId} subarticle_id={subarticleId} recurring_id={recurringId} recurrence_pos={recurrencePos}
                from={from} to={to} onChange={onTimeIntervalChanged} readOnly={isReadOnly}/>

            <CountAndHandedOutSection article_id={articleId} subarticle_id={subarticleId} recurring_id={recurringId} recurrence_pos={recurrencePos}
                count={count} isSubarticle={isSubarticle} onDoCountChange={onCountChange} isReservation={isReservation} isAvailable={isAvailable} readOnly={isReadOnly}
                handouts={handouts} returned_count={returnedCount} />

            <ReturnsSection article_id={articleId} subarticle_id={subarticleId} recurring_id={recurringId} recurrence_pos={recurrencePos}
                isReservation={isReservation} isAvailable={isAvailable} readOnly={isReadOnly}
                handouts_count={handedOutCount} returns={returns} />

            <DeleteArticleButton article={props.article} subarticle={props.subarticle} />

            {showSpeziTooltip &&
                <ReactTooltip id={idForTooltip} className="react-tooltip"  place="bottom" offset={{left: -55}} type="light" arrowColor="transparent">
                    {renderSpeziTooltip(subarticle.spezi)}
                </ReactTooltip>
            }
        </div>

    );
}

ArticlesTableEntry.propTypes = {
    article: PropTypes.object.isRequired,
    subarticle: PropTypes.object,
    first: PropTypes.bool
}

export default ArticlesTableEntry;
