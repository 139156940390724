/* globals moment */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import FileSelectButton from '../common/components/FileSelectButton';
import SearchField from '../common/components/SearchField';

import { settingsPriceLang } from './SettingsPriceConfig'
import SettingsPriceApi from './api/SettingsPriceApi'

import { selectSearchTerm, selectLastCostCenterImport, getSettingsPrice, getCostCentersData} from './store/settingsPriceSlice';

import { formatString } from "../common/utils";

const SettingsPriceDataTableHeader = (props) => {
    const [importMesaage, setImportMessage] = useState("");

    const dispatch = useDispatch();

    const lastCostCenterImport = useSelector(selectLastCostCenterImport);
    const searchTerm = useSelector(selectSearchTerm);

    const onCostCentersFileSelected = async (file) => {
        setImportMessage(settingsPriceLang.data_table.importing);

        let response = await SettingsPriceApi.uploadCostCentersImportFile(file);

        if (response.ok) {
            setImportMessage(formatString(settingsPriceLang.messages.import_success, response.message));
        } else {
            setImportMessage(formatString(settingsPriceLang.messages.import_failed, response.message));
        }

        dispatch(getSettingsPrice());
        dispatch(getCostCentersData());
    }
    
    let lastCostCenterImportMessage = "";
    if (lastCostCenterImport) {
        const lastCostCenterImportDate = moment(lastCostCenterImport, 'YYYY-MM-DD HH:mm:ss');

        lastCostCenterImportMessage = formatString(settingsPriceLang.messages.last_import_date, lastCostCenterImportDate.format('DD.MM.YYYY HH:mm'));
    }

    return (
        <>
            <div className="header data-table-header dash-block-header flex flex__align--center">
                <div className="settings-price-header-table-header-title">{settingsPriceLang.data_table.title}</div>
                <div className="table-header-content-right">
                    <div className="table-header-content-right-item">
                        <a download href={SettingsPriceApi.getDownloadUrlForTemplate()}>

                            <svg id="svg_download_costcenters_templage" className="svg_icon">
                                <use xlinkHref={"#icon-download"}></use>
                            </svg>
                            
                            {settingsPriceLang.data_table.download_template}
                        </a>
                    </div>
                </div>
            </div>

            <div className="header-content">
                <div className="header-content-entry-row" style={{alignItems: "center"}}>
                    <FileSelectButton label={settingsPriceLang.data_table.import_cost_centers} onFileSelected={onCostCentersFileSelected} allowedFileExtensions={[".csv"]} />
                    &nbsp;&nbsp;{lastCostCenterImportMessage}
                    &nbsp;&nbsp;<span className='import_message'>{importMesaage}</span>
                </div>
               
                <div style={{marginLeft: "auto"}}>
                    <SearchField onDoSearch={props.onDoSearch} searchTerm={searchTerm} />
                </div>
            </div>
        </>
    )
}

SettingsPriceDataTableHeader.propTypes = {
    onDoSearch: PropTypes.func.isRequired
};

export default SettingsPriceDataTableHeader;
