import React from "react";
import "../../common/scss/common.scss";
import "./ShopSearchTermsStatisticsApp.scss";

import { shopSearchTermsStatisticsConfigLang } from './ShopSearchTermsStatisticsConfig'

import PageTitle from '../../common/components/PageTitle'

import ShopSearchTermsStatisticsTableHeader from "./ShopSearchTermsStatisticsHeader";
import ShopSearchTermsStatisticsTable from "./ShopSearchTermsStatisticsTable";

const ShopSearchTermsStatisticsApp = () => {
    return (
        <div id="shop_search_terms_statistics_app_content" className="col-one-full">
            <PageTitle title={shopSearchTermsStatisticsConfigLang.page_title} />

            <div className="flex flex__col">
                <div className="data-table-block dash-block-container-sp data-table-block-extended ">
                    <ShopSearchTermsStatisticsTableHeader />

                    <div className="table_wrapper">
                        <ShopSearchTermsStatisticsTable />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopSearchTermsStatisticsApp;