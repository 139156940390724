
import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

const LinkButton = (props) => {
    let cssClasses = classNames(["link_button", props.className]);

    return (
        <a href={props.link} target="_blank" className={cssClasses} rel="noreferrer">
            {props.textLeft != '' &&
                <>
                    <span className="action_button small">{props.textLeft}</span>
                    &nbsp;&nbsp;
                </>
            }
            <span className="action_text">{props.textRight}</span>
        </a>
    );
}

LinkButton.propTypes = {
    className: PropTypes.string,
    link: PropTypes.string.isRequired,
    textLeft: PropTypes.string.isRequired,
    textRight: PropTypes.string.isRequired
}

export default LinkButton;