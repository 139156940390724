import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import QVDataTable from '../common/components/QVDataTable';

import { costCenterTableColumns } from './SettingsPriceConfig'

import {
    getCostCentersData,

    setPageNumber,
    setRowsOnPage,

    selectRowsOnPage,
    selectTotalRows,
    selectCostCenterData,

    selectIsLoading
} from './store/settingsPriceSlice';

const SettingsPriceDataTable = (props) => {
    const dispatch = useDispatch();

    const costCenterData = useSelector(selectCostCenterData);
    const isLoading = useSelector(selectIsLoading);
    const totalRows = useSelector(selectTotalRows);
    const rowsPerPage = useSelector(selectRowsOnPage);
	
	const handlePageChange = page => {
        dispatch(setPageNumber(page));
        dispatch(getCostCentersData());
	};

	const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(setRowsOnPage(newPerPage));
        dispatch(setPageNumber(page));
        dispatch(getCostCentersData());
	};

    // const handleSort = (column, sortDirection) => {
    //     dispatch(setSortField(column.sortField));
    //     dispatch(setSortDirection(sortDirection));
    //     dispatch(getRentalHistoryData());
    // };

	return (
        <QVDataTable
            title=""
			columns={costCenterTableColumns}
			data={costCenterData}
			isLoading={isLoading}
			paginationTotalRows={totalRows}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
            paginationPerPage={rowsPerPage}
            noDataToShowText=""
		/>
	);
}

export default SettingsPriceDataTable;