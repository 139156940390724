
import lang from "../../common/lang/de.json";

export const institutionsStatisticsConfigLang = lang.institutions_statistics;

export const institutionsStatisticsTableColumns = [
    {
        name: institutionsStatisticsConfigLang.data_table.columns_names.name,
        selector: row => row.name,
        minWidth: "500px",
        sortable: false,
    },
    {
        name: institutionsStatisticsConfigLang.data_table.columns_names.usage,
        selector: row => row.usage,
        right: true,
        minWidth: "300px",
        sortable: false,
    }
];
