import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import "./ArticlesPopupHeader.scss";
import lang from "../../../common/lang/de.json";

import ArticlesPopupFilters from './ArticlesPopupFilters';

import { 
    getCategories, 
    getLocationsForCustomer
 } from "../../store/articlesPopupSlice";

const ArticlesPopupHeader = () => {
    const dispatch = useDispatch();
    const articlesPopupHeaderLang = lang.reservation.articlesPopup.articlesPopupHeader;
    
    useEffect(() => {
        dispatch(getCategories());
        dispatch(getLocationsForCustomer());
    }, [])

    return (
        <div className="categories-selector-continer">
            <ArticlesPopupFilters lang={articlesPopupHeaderLang.articlesPopupFilters}/>
        </div>
    )
}

export default ArticlesPopupHeader;