/* globals moment */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import FileSelectButton from '../common/components/FileSelectButton';
import SearchField from '../common/components/SearchField';
import ActionButton from '../common/components/ActionButton';
import PopupAddInstitution from './PopupAddInstitution';

import { importInstitutionsLang } from './ImportInstitutionsConfig'
import InstitutionsApi from './api/InstitutionsApi'

import { selectSearchTerm, selectLastInstitutionsImport, getInstitutionsSettings, getInstitutionsData, addInstitution } from './store/importInstitutionsSlice';

import { formatString } from "../common/utils";

const ImportInstitutionsDataTableHeader = (props) => {
    const [importMesaage, setImportMessage] = useState("");

    const dispatch = useDispatch();

    const lastInstitutionsImport = useSelector(selectLastInstitutionsImport);
    const searchTerm = useSelector(selectSearchTerm);

    const onInsitutionsFileSelected = async (file) => {
        setImportMessage(importInstitutionsLang.data_table.importing);

        let response = await InstitutionsApi.uploadInstitutionsImportFile(file);

        if (response.ok) {
            setImportMessage(formatString(importInstitutionsLang.messages.import_success, response.message));
        } else {
            setImportMessage(formatString(importInstitutionsLang.messages.import_failed, response.message));
        }

        dispatch(getInstitutionsSettings());
        dispatch(getInstitutionsData());
    }
    
    let lastInstitutionsImportMessage = "";
    if (lastInstitutionsImport) {
        const lastInstitutionsImportDate = moment(lastInstitutionsImport, 'YYYY-MM-DD HH:mm:ss');

        lastInstitutionsImportMessage = formatString(importInstitutionsLang.messages.last_import_date, lastInstitutionsImportDate.format('DD.MM.YYYY HH:mm'));
    }

    const [showPopup, setShowPopup] = useState(false);

    const onAddInstitution = () => {
        setShowPopup(true);
    };

    const handleAddInstitution = async (formData) => {
        await dispatch(addInstitution(formData));
        dispatch(getInstitutionsData());
        setShowPopup(false);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    }

    return (
        <>
            <div className="header data-table-header dash-block-header flex flex__align--center">
                <div className="settings-price-header-table-header-title">{importInstitutionsLang.data_table.title}</div>
                <div className="table-header-content-right">
                    <div className="table-header-content-right-item">
                        <a download href={InstitutionsApi.getDownloadUrlForTemplate()}>

                            <svg id="svg_download_institutions_template" className="svg_icon" style={{marginRight: "10px"}}>
                                <use xlinkHref={"#icon-download"}></use>
                            </svg>
    
                            {importInstitutionsLang.data_table.download_template}
                        </a>
                    </div>
                </div>
            </div>

            <div className="header-content">
                <div className="header-content-entry-row" style={{alignItems: "center"}}>
                    <ActionButton onClick={onAddInstitution} text={importInstitutionsLang.data_table.add_institution} style={{marginRight: "10px"}} />
                    {showPopup && (
                        <PopupAddInstitution
                            onAddInstitution={handleAddInstitution}
                            onClose={handleClosePopup}
                        />
                    )}

                    <FileSelectButton label={importInstitutionsLang.data_table.import_institutions} onFileSelected={onInsitutionsFileSelected} allowedFileExtensions={[".csv"]} />
                    &nbsp;&nbsp;{lastInstitutionsImportMessage}
                    &nbsp;&nbsp;<span className='import_message'>{importMesaage}</span>
                </div>
               
                <div style={{marginLeft: "auto"}}>
                    <SearchField onDoSearch={props.onDoSearch} searchTerm={searchTerm} />
                </div>
            </div>
        </>
    )
}

ImportInstitutionsDataTableHeader.propTypes = {
    onDoSearch: PropTypes.func.isRequired
};

export default ImportInstitutionsDataTableHeader;
