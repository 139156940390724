import { client } from '../../common/api/client';

const baseUrl = "/includes/kunden/";
const baseApiUrl = baseUrl + "api/customers.php?method=";

function getApiUrl(action) {
    return baseApiUrl + action;
}

class CustomersApi {

    static getCustomersData(searchTerm, rowsOnPage, pageNumber, sortField, sortOrder) {
        return client.post(getApiUrl("getCustomersData"), {
            searchTerm,
            rowsOnPage,
            pageNumber,
            sortField,
            sortOrder
        });
    }
}

export default CustomersApi;
