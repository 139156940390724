import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { rentalHistoryLang } from './RentalHistoryConfig'

import QVDataTable from '../../common/components/QVDataTable';
import SvgButton from "../../common/components/SvgButton";

import { columns } from './RentalHistoryConfig'

import {
    getRentalHistoryData,

    setPageNumber,
    setRowsOnPage,

    setSortField,
    setSortDirection,

    selectRentalHistoryData,
    selectTotalRows,
    selectRowsOnPage,
    selectIsLoading
} from './store/rentalHistorySlice';

const RentalHistoryDataTable = (props) => {
    const dispatch = useDispatch();

    const rentalHistoryData = useSelector(selectRentalHistoryData);
    const isLoading = useSelector(selectIsLoading);
    const totalRows = useSelector(selectTotalRows);
    const perPage = useSelector(selectRowsOnPage);

    const handlePageChange = page => {
        dispatch(setPageNumber(page));
        dispatch(getRentalHistoryData());
	};

	const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(setRowsOnPage(newPerPage));
        dispatch(getRentalHistoryData());
	};

    const handleOpenReservation = (row, index, column, id) => {
        window.open(row.contract_link, '_blank').focus();
    }

    const handleSort = (column, sortDirection) => {
        dispatch(setSortField(column.sortField));
        dispatch(setSortDirection(sortDirection));
        dispatch(getRentalHistoryData());
    };

    const buildActionColumn = (row, index, column, id) => {

        return (
            <SvgButton svgRef="icon-pen" textRight="" onClick={ () => handleOpenReservation(row, index, column, id)} />
        )
    }

	const actionsColumn = {
        cell: buildActionColumn,
        selector: row => row.contract_link,
        width: "50px",
    }

	return (
		<QVDataTable
            title=""
			columns={[...columns, actionsColumn]}
			data={rentalHistoryData}
			isLoading={isLoading}
			paginationTotalRows={totalRows}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
            paginationPerPage={perPage}
            defaultSortAsc={false}
            onSort={handleSort}
            noDataToShowText={rentalHistoryLang.no_data_to_show}
		/>
	);
}

export default RentalHistoryDataTable;