import React from 'react';
import PropTypes from 'prop-types';

import ArticlesTableEntry from './ArticlesTableEntry';
import AddSubarticleButton from './AddSubarticleButton';

const ArticlesTableEntryWithSubarticles = (props) => {
    const { article } = props;

    return (
        <div className='article_with_subarticles'>
            {article.subarticles.map((subarticle, i) => 
                <ArticlesTableEntry key={subarticle.subarticle_id} first={i == 0} article={article} subarticle={subarticle} />)
            }

            {article.recurring_id == "0" && article.recurrence_pos == "0" &&
                <AddSubarticleButton article={article} more_subarticles_available={article.more_subarticles_available} />
            }
        </div>

    );
}

ArticlesTableEntryWithSubarticles.propTypes = {
    article: PropTypes.object.isRequired
}

export default ArticlesTableEntryWithSubarticles;
