import React from "react";
import lang from "../common/lang/de.json";
import SvgButton from "../common/components/SvgButton";

export const customersConfigLang = lang.customers;

export const customersTableColumns = (institutionsEnabled) => [
    {
        name: customersConfigLang.data_table.columns_names.first_name,
        minwidth: "100px",
        selector: row => row.first_name,
        sortable: true,
        sortField: "first_name"
    },
    {
        name: customersConfigLang.data_table.columns_names.last_name,
        minwidth: "100px",
        selector: row => row.last_name,
        sortable: true,
        sortField: "last_name"
    },
    {
        name: customersConfigLang.data_table.columns_names.matriculation_number,
        minwidth: "100px",
        selector: row => row.matriculation_number,
        sortable: true,
        sortField: "matriculation_number"
    },
    {
        name: customersConfigLang.data_table.columns_names.institution,
        omit: !institutionsEnabled,
        minwidth: "100px",
        selector: row => row.institution,
        sortable: true,
        sortField: "institution"
    },
    {
        name: customersConfigLang.data_table.columns_names.street,
        minwidth: "100px",
        selector: row => row.street,
        sortable: true,
        sortField: "street"
    },
    {
        name: customersConfigLang.data_table.columns_names.zip_code,
        width: "75px",
        selector: row => row.zip_code,
        sortable: true,
        sortField: "zip_code"
    },
    {
        name: customersConfigLang.data_table.columns_names.city,
        minwidth: "100px",
        selector: row => row.city,
        sortable: true,
        sortField: "city"
    },
    {
        name: customersConfigLang.data_table.columns_names.mobile,
        width: "100px",
        selector: row => row.mobile,
        sortable: true,
        sortField: "mobile"
    },
    {
        name: customersConfigLang.data_table.columns_names.landline,
        width: "100px",
        selector: row => row.landline,
        sortable: true,
        sortField: "landline"
    },
    {
        name: customersConfigLang.data_table.columns_names.callback_number,
        width: "100px",
        selector: row => row.callback_number,
        sortable: true,
        sortField: "callback_number"
    },
    {
        name: customersConfigLang.data_table.columns_names.email,
        minwidth: "200px",
        selector: row => row.email,
        sortable: true,
        sortField: "email"
    },
    {
        name: customersConfigLang.data_table.columns_names.action,
        // eslint-disable-next-line react/display-name
        cell: row => (
            <SvgButton
                svgRef="icon-pen"
                className="add-button"
                onClick={() =>  window.location.href = `/index.php?page=3-1_Customer_bearbeiten&edit_id=${row.id}`}
            />
        ),
        width: "50px",
        sortable: false
    }
];
