import React from 'react';
import { useSelector } from 'react-redux';

import ReactTooltip from 'react-tooltip';
import DOMPurify from 'dompurify';

import lang from "../common/lang/de.json";

import {
    selectReservationCustomerLocations,
} from './store/reservationSlice';

const ReservationCustomerLocations = () => {
    const reservationCustomerLocations = useSelector(selectReservationCustomerLocations);
    const idForTooltip = "locations_tooltip";

    const renderLocationsTooltip = (locations) => {
        let locationsToolTip = [];
        locationsToolTip.push(
            <div key={'locations_title'} >
                <div className='title'>{lang.reservation.locations_tooltip_title}:</div>
            </div>
        );
        
        for(var location of locations) {
            locationsToolTip.push(
                <div key={'location_'+location.id} >
                    <div className='data small-bottom-margin' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(location.name) }} />
                </div>
            );
        }

        return locationsToolTip;
    };

    return (
        <div className="customer-locations" data-tip='' data-for={idForTooltip} >
            <span className="locations_label">{lang.reservation.customer_locations_label}</span>{" "+reservationCustomerLocations[0].name}
            { reservationCustomerLocations && reservationCustomerLocations.length > 1 &&
                <span className="more-locations"> + {reservationCustomerLocations.length -1} {reservationCustomerLocations.length > 2 ? lang.reservation.more_locations : lang.reservation.one_more_location}</span>
            }

            <ReactTooltip id={idForTooltip} className="react-tooltip"  place="bottom" offset={{left: -55}} type="light" arrowColor="transparent">
                {renderLocationsTooltip(reservationCustomerLocations)}
            </ReactTooltip>
        </div>
    );
}

export default ReservationCustomerLocations;