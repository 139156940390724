import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import ArticlesUsageStatisticsApp from './ArticlesUsageStatisticsApp';

import articlesUsageStatisticsReducer from './store/articlesUsageStatisticsSlice';

// the interface between js app and react app
window.quickverleih.articles_usage_statistics_app = (function() {

    function init() {

        let articlesUsageStatisticsStore = configureStore({
            reducer: {
                article_usage_statistics: articlesUsageStatisticsReducer,
            }
        })

        ReactDOM.render(
            <Provider store={articlesUsageStatisticsStore}>
                <ArticlesUsageStatisticsApp />
            </Provider>,
            document.getElementById("articles_usage_statistics_app")
        );
    }

    return {
        init
    };
})();