import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ArticlesApi from "../api/ArticlesApi";

const initialState = {
    status: 'idle',
    error: null,

    searchTerm: "",

    articlesData: [],
    rowsOnPage: 20,
    pageNumber: 1,
    totalRows: 0,
    sortColumn: 'gnr',
    sortDirection: 'asc'
};


export const getArticlesData = createAsyncThunk(
    'articles/get_articles_data',
    async (_noParams, { getState }) => {
        
        const searchTerm = getState().articles.searchTerm;
        
        const rowsOnPage = getState().articles.rowsOnPage;
        const pageNumber = getState().articles.pageNumber;
        const sortColumn = getState().articles.sortColumn;
        const sortDirection = getState().articles.sortDirection;

        const response = await ArticlesApi.getArticlesData(searchTerm, rowsOnPage, pageNumber, sortColumn, sortDirection);
        return response;
    }
)

export const slice = createSlice({
    name: 'articles',
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setRowsOnPage: (state, action) => {
            state.rowsOnPage = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setSortDirection: (state, action) => {
            state.sortDirection = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getArticlesData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getArticlesData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.articlesData = action.payload.articles_data;
                state.totalRows = parseInt(action.payload.articles_count);
            })
            .addCase(getArticlesData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const selectIsLoading = state => state.articles.status === "loading";
export const selectPageNumber = state => state.articles.pageNumber;
export const selectRowsOnPage = state => state.articles.rowsOnPage;
export const selectTotalRows = state => state.articles.totalRows;
export const selectSearchTerm = state => state.articles.searchTerm;
export const selectArticlesData = state => state.articles.articlesData;

export const { setSearchTerm, setRowsOnPage, setPageNumber, setSortColumn, setSortDirection } = slice.actions;

export default slice.reducer;
