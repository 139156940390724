
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import InstitutionsApi from "../api/InstitutionsApi";

const initialState = {
    status: 'idle',
    error: null,

    enableInstitutions: false,
    lastInstiutionsImport: null,

    rowsOnPage: 20,
    pageNumber: 1,
    totalRows: 0,

    searchTerm: "",

    institutionsData: [],
};

export const getInstitutionsSettings = createAsyncThunk(
    'import_institutions/get_settings',
    async () => {
        try {
            const response = await InstitutionsApi.getSettings();
            return response;
        } catch (error) {
            throw Error('Failed to fetch settings for institutions');
        }
    }
);

export const setInstitutionsSettings = createAsyncThunk(
    'import_institutions/set_settings',
    async ({enableInstitutions}) => {
        try {
            const response = await InstitutionsApi.setSettings(enableInstitutions);
            return response;
        } catch (error) {
            throw Error('Failed to set settings for institutions');
        }
    }
);

export const getInstitutionsData = createAsyncThunk(
    'import_institutions/get_institutions_data',
    async (_noParams, { getState }) => {
        const rowsOnPage = getState().import_institutions.rowsOnPage;
        const pageNumber = getState().import_institutions.pageNumber;

        // const sortField = getState().import_institutions.sortField;
        // const sortDirection = getState().import_institutions.sortDirection;
        
        const searchTerm = getState().import_institutions.searchTerm;
        
        const response = await InstitutionsApi.getInstitutionsData(searchTerm, rowsOnPage, pageNumber);
        return response;
    }
)

export const addInstitution = createAsyncThunk(
    'import_institutions/add_institution',
    async (institutionData) => {
        try {
            const response = await InstitutionsApi.addInstitution(institutionData);
            return response;
        } catch (error) {
            throw Error('Failed to add institution');
        }
    }
);

const setStateInstitutionsSettings = (state, response) => {
    state.enableInstitutions = response.institutions_settings.institutions_enabled;
    state.lastInstiutionsImport = response.institutions_settings.last_institutions_import;
}

export const slice = createSlice({
    name: 'import_institutions',
    initialState,
    reducers: {
        setEnableInstitutions: (state, action) => {
            state.enableInstitutions = action.payload;
        },
        setRowsOnPage: (state, action) => {
            state.rowsOnPage = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInstitutionsSettings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getInstitutionsSettings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                
                const response = action.payload;
                setStateInstitutionsSettings(state, response);
            })
            .addCase(getInstitutionsSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(setInstitutionsSettings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(setInstitutionsSettings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                
                const response = action.payload;
                setStateInstitutionsSettings(state, response);
            })
            .addCase(setInstitutionsSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getInstitutionsData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getInstitutionsData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.institutionsData = action.payload.institutions_data;
                state.totalRows = parseInt(action.payload.institutions_count);
            })
            .addCase(getInstitutionsData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(addInstitution.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addInstitution.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(addInstitution.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const selectIsLoading = state => state.import_institutions.status === "loading";
export const selectEnableInstitutions = state => state.import_institutions.enableInstitutions;
export const selectLastInstitutionsImport = state => state.import_institutions.lastInstiutionsImport;
export const selectPageNumber = state => state.import_institutions.pageNumber;
export const selectRowsOnPage = state => state.import_institutions.rowsOnPage;
export const selectTotalRows = state => state.import_institutions.totalRows;
export const selectInstitutionsData = state => state.import_institutions.institutionsData;
export const selectSearchTerm = state => state.import_institutions.searchTerm;

export const { setEnableInstitutions, setRowsOnPage, setPageNumber, setSearchTerm } = slice.actions;

export default slice.reducer;
