/* global $, quickverleih */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { decodeHtmlEntities } from '../utils';

const WysiwygEditor = ({
    initialValue = '',
    onChange,
    id = 'wysiwyg-field',
    placeholder = ''
}) => {
    const editorRef = useRef(null);

    useEffect(() => {
        quickverleih.editor.init(`#${id}`, null, placeholder, onChange);
    }, []);

    return (
        
        <div className="wysiwyg-editor">
            <textarea
                ref={editorRef}
                id={id}
                name={id}
                rows={10}
                style={{ width: '100%' }}
                defaultValue={decodeHtmlEntities(initialValue)}
            />
        </div>
    );
}
WysiwygEditor.propTypes = {
    initialValue: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string,
    placeholder: PropTypes.string
};

export default WysiwygEditor;
