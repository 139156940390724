import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import QVDataTable from '../common/components/QVDataTable';

import { costCenterTableColumns } from './ImportInstitutionsConfig'

import {
    getInstitutionsData,

    setPageNumber,
    setRowsOnPage,

    selectRowsOnPage,
    selectTotalRows,
    selectInstitutionsData,

    selectIsLoading
} from './store/importInstitutionsSlice';

const ImportInstitutionsDataTable = (props) => {
    const dispatch = useDispatch();

    const institutionsData = useSelector(selectInstitutionsData);
    const isLoading = useSelector(selectIsLoading);
    const totalRows = useSelector(selectTotalRows);
    const rowsPerPage = useSelector(selectRowsOnPage);
	
	const handlePageChange = page => {
        dispatch(setPageNumber(page));
        dispatch(getInstitutionsData());
	};

	const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(setRowsOnPage(newPerPage));
        dispatch(setPageNumber(page));
        dispatch(getInstitutionsData());
	};

    const onRowClicked = (row) => {
        window.open(`/index.php?page=customers-overview&filter_institution=${row.id}`, '_blank');
    }

    // const handleSort = (column, sortDirection) => {
    //     dispatch(setSortField(column.sortField));
    //     dispatch(setSortDirection(sortDirection));
    //     dispatch(getRentalHistoryData());
    // };

	return (
        <QVDataTable
            title=""
			columns={costCenterTableColumns}
			data={institutionsData}
			isLoading={isLoading}
			paginationTotalRows={totalRows}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
            paginationPerPage={rowsPerPage}
            onRowClicked={onRowClicked}
            noDataToShowText=""
		/>
	);
}

export default ImportInstitutionsDataTable;