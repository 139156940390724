import { client } from '../../../common/api/client';

const settingsBaseUrl = "/includes/reports/";
const baseApiUrl = settingsBaseUrl + "api/cost_centers_statistics.php?method=";

function getApiUrl(action) {
    return baseApiUrl + action;
}

class CostCentersStatisticsApi {

    static getCostCentersStatisticsData(searchTerm, from, to) {
        return client.post(getApiUrl("getCostCentersStatisticsData"), {
            searchTerm,
            from,
            to
        });
    }
}

export default CostCentersStatisticsApi;
