import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import "./ReservationExtraPDFText.scss";

import WysiwygEditor from "../common/components/WysiwygEditor";

import lang from "../common/lang/de.json";

import { selectPdfText, setPDFText, updatePDFText } from './store/reservationSlice';

const ReservationExtraPDFText = () => {
    const dispatch = useDispatch();
    const pdfText = useSelector(selectPdfText);

    const handlePDFTextChange = useCallback(
        debounce((value) => {
            dispatch(setPDFText(value));
            dispatch(updatePDFText(value));
        }, 400),
        [dispatch]
    );

    if(pdfText === null) {
        return null;
    }

    return (
        <div className="reservation-extra-pdf-text dash-block-container">
            <div className="dash-block-header text-uppercase">{lang.reservation.pdf_text.title}</div>

            <div className="dash-block-content">
                <WysiwygEditor 
                    id="reservation-pdf-note"
                    placeholder={lang.reservation.pdf_text.placeholder}
                    initialValue={pdfText}
                    onChange={handlePDFTextChange}
                />
            </div>
        </div>
    );
};

export default ReservationExtraPDFText;
