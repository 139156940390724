/* globals moment */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CostCentersStatisticsApi from "../api/CostCentersStatisticsApi";

import { convertDateForServer } from "../../../common/utils";

const initialState = {
    status: 'idle',
    error: null,

    searchTerm: "",
    from: moment().minute(0).subtract(7, 'days').format("DD.MM.YYYY HH:mm"),
    to: moment().minute(0).format("DD.MM.YYYY HH:mm"),

    costCentersStatisticsData: [],
};


export const getCostCentersStatisticsData = createAsyncThunk(
    'cost_centers_statistics/get_cost_centers_data',
    async (_noParams, { getState }) => {
        
        const searchTerm = getState().cost_centers_statistics.searchTerm;
        const from = getState().cost_centers_statistics.from;
        const to = getState().cost_centers_statistics.to;
        
        const response = await CostCentersStatisticsApi.getCostCentersStatisticsData(searchTerm, convertDateForServer(from), convertDateForServer(to));
        return response;
    }
)

export const slice = createSlice({
    name: 'cost_centers_statistics',
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setFromTo: (state, action) => {
            state.from = action.payload.from;
            state.to = action.payload.to;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCostCentersStatisticsData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCostCentersStatisticsData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.costCentersStatisticsData = action.payload.cost_centers_statistics_data;
            })
            .addCase(getCostCentersStatisticsData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const selectIsLoading = state => state.cost_centers_statistics.status === "loading";
export const selectSearchTerm = state => state.cost_centers_statistics.searchTerm;
export const selectFrom = state => state.cost_centers_statistics.from;
export const selectTo = state => state.cost_centers_statistics.to;
export const selectCostCentersStatisticsData = state => state.cost_centers_statistics.costCentersStatisticsData;

export const { setSearchTerm, setFromTo } = slice.actions;

export default slice.reducer;