import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import ImportInstitutionsApp from './ImportInstitutionsApp';

import importInstitutionsReducer from './store/importInstitutionsSlice';

// the interface between js app and react app
window.quickverleih.import_institutions_app = (function() {

    function init() {

        let importInstitutionsStore = configureStore({
            reducer: {
                import_institutions: importInstitutionsReducer,
            }
        })

        ReactDOM.render(
            <Provider store={importInstitutionsStore}>
                <ImportInstitutionsApp />
            </Provider>,
            document.getElementById("import_institutions_app")
        );
    }

    return {
        init
    };
})();