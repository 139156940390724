
import React from "react";
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import classNames from "classnames";

const ActionButton = (props) => {
    const cssClasses = classNames(["action_button", (props.disabled ? "gray" : props.className)]);

    const onClick = () => {
        if(!props.disabled && props.onClick) {
            props.onClick();
        }
    }

    return (
        <>
        <div 
            data-place={props.tooltip_position || "bottom"} 
            data-tip={props.tooltip_text || ""} 
            data-for={props.tooltip_id} 
            data-multiline={props.tooltip_multiline || true} 
            data-arrow-color={props.tooltip_arrow_color || "transparent"} 
            className={cssClasses} 
            onClick={onClick} 
            style={{width: "min-content", whiteSpace: "nowrap", ...props.style}}>
                {props.text}
        </div>
        {props.tooltip_show &&
            <ReactTooltip id={props.tooltip_id} className="action-button-tooltip" type="light" />
        }
        </>
    );
}

ActionButton.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    style: PropTypes.object,

    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,

    // Tooltip
    tooltip_show: PropTypes.bool,
    tooltip_position: PropTypes.string,
    tooltip_text: PropTypes.string,
    tooltip_id: PropTypes.string,
    tooltip_multiline: PropTypes.bool,
    tooltip_arrow_color: PropTypes.string
}

export default ActionButton;