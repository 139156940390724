import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CustomersApi from "../api/CustomersApi";

const initialState = {
    status: 'idle',
    error: null,

    searchTerm: "",
    institutionsEnabled: false,

    customersData: [],
    rowsOnPage: 20,
    pageNumber: 1,
    totalRows: 0,

    sortField: 'last_name',
    sortOrder: 'asc',
};

export const getCustomersData = createAsyncThunk(
    'customers/get_customers_data',
    async (_noParams, { getState }) => {
        
        const searchTerm = getState().customers.searchTerm;
        
        const rowsOnPage = getState().customers.rowsOnPage;
        const pageNumber = getState().customers.pageNumber;
        
        const sortField = getState().customers.sortField;
        const sortOrder = getState().customers.sortOrder;

        const response = await CustomersApi.getCustomersData(searchTerm, rowsOnPage, pageNumber, sortField, sortOrder);
        return response;
    }
)

export const slice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setInstitutionsEnabled: (state, action) => {
            state.institutionsEnabled = action.payload;
        },
        setRowsOnPage: (state, action) => {
            state.rowsOnPage = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        },
        setSortField: (state, action) => {
            state.sortField = action.payload;
        },
        setSortOrder: (state, action) => {
            state.sortOrder = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCustomersData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCustomersData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.customersData = action.payload.customers_data;
                state.totalRows = parseInt(action.payload.customers_count);
                state.institutionsEnabled = action.payload.institutions_enabled;
            })
            .addCase(getCustomersData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const selectIsLoading = state => state.customers.status === "loading";
export const selectPageNumber = state => state.customers.pageNumber;
export const selectRowsOnPage = state => state.customers.rowsOnPage;
export const selectTotalRows = state => state.customers.totalRows;
export const selectSearchTerm = state => state.customers.searchTerm;
export const selectInstitutionsEnabled = state => state.customers.institutionsEnabled;
export const selectSortField = state => state.customers.sortField;
export const selectSortOrder = state => state.customers.sortOrder;
export const selectCustomersData = state => state.customers.customersData;

export const { setSearchTerm, setInstitutionsEnabled, setRowsOnPage, setPageNumber, setSortField, setSortOrder } = slice.actions;

export default slice.reducer;
