import React from 'react';
import he from 'he';

import lang from "../../common/lang/de.json";

export const articlesUsageStatisticsConfigLang = lang.article_usage_statistics;

export const articlesUsageStatisticsTableColumns = [
    {
        name: articlesUsageStatisticsConfigLang.data_table.columns_names.number,
        selector: row => row.article_number,
        width: "100px",
        minWidth: "100px",
        sortable: false,
    },
    {
        name: articlesUsageStatisticsConfigLang.data_table.columns_names.description,
        cell: row => (<div>{he.decode(row.article_name)}</div>),
        width: "300px",
        minWidth: "300px",
        sortable: false,
    },
    {
        name: <div>sadaf</div>,
        minWidth: "500px",
        sortable: false
    }
];
