import React from 'react';

import ReservationReminders from "./ReservationReminders";
import ReservationHistory from "./ReservationHistory";

const ReservationFooter = () => {
    return (
        <div className="bottom_tables">
            <ReservationReminders />

            <ReservationHistory />
        </div>
    );
}

export default ReservationFooter;