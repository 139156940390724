/* global qvapp */
import lang from "../../common/lang/de.json";

export const reportsCommonLang = lang.reports.common;

export const rentalHistoryLang = lang.reports.rental_history;

export const columns = [
    {
        name: reportsCommonLang.data_table.table_columns_names.contract_number,
        selector: row => row.vertragsnr,
        width: "130px",
        minWidth: "130px",
        sortable: true,
        sortField: 'vertragsnr',
    },
    {
        name: reportsCommonLang.data_table.table_columns_names.article_number,
        selector: row => row.gnr,
        width: "80px",
        minWidth: "80px",
        sortable: true,
        sortField: 'gnr',
    },
    {
        name: reportsCommonLang.data_table.table_columns_names.article_name,
        selector: row => row.name,
        minWidth: "200px",
        grow: 2,
        sortable: true,
        sortField: 'name',
    },
    {
        name: reportsCommonLang.data_table.table_columns_names.quantity,
        selector: row => row.a_menge,
        width: "50px",
        minWidth: "50px"
    },
    {
        name: qvapp.i18n.t("label_lender"),
        selector: row => row.customer_name,
        minWidth: "200px",
        grow: 1,
        sortable: true,
        sortField: 'customer_name',
    },
    {
        name: reportsCommonLang.data_table.table_columns_names.start,
        selector: row => row.start,
        width: "130px",
        minWidth: "130px",
        sortable: true,
        sortField: 'a_von',
    },
    {
        name: reportsCommonLang.data_table.table_columns_names.end,
        selector: row => row.end,
        width: "130px",
        minWidth: "130px",
        sortable: true,
        sortField: 'a_bis',
    },
    {
        name: reportsCommonLang.data_table.table_columns_names.status,
        selector: row => row.status,
        width: "170px",
        minWidth: "170px"
    }
];

export const filterByColumns = [
    {
        label: rentalHistoryLang.header.filter_by_columns.article_number,
        value: "gnr"
    },
    {
        label: qvapp.i18n.t("label_lender"),
        value: "customer",
    }
];