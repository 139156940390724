import { client } from '../../common/api/client';

const baseUrl = "/includes/artikel/";
const baseApiUrl = baseUrl + "api/articles.php?method=";

function getApiUrl(action) {
    return baseApiUrl + action;
}

class ArticlesApi {

    static getArticlesData(searchTerm, rowsOnPage, pageNumber, sortColumn, sortDirection) {
        return client.post(getApiUrl("getArticlesData"), {
            searchTerm,
            rowsOnPage,
            pageNumber,
            sortColumn,
            sortDirection
        });
    }
}

export default ArticlesApi;
