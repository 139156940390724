import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SearchField from '../common/components/SearchField';

import { customersConfigLang } from './CustomersConfig'

import { selectSearchTerm, setSearchTerm } from './store/customersSlice';

const CustomersHeader = () => {
    const dispatch = useDispatch();

    const searchTerm = useSelector(selectSearchTerm);

    const onDoSearch = (searchTerm) => {
        dispatch(setSearchTerm(searchTerm));
    }

    return (
        <>
            <div className="header data-table-header dash-block-header flex flex__align--center">
                <div className="settings-price-header-table-header-title">{customersConfigLang.data_table.title}</div>

                <div style={{marginLeft: "auto"}}>
                    <SearchField onDoSearch={onDoSearch} searchTerm={searchTerm} />
                </div>
            </div>
        </>
    )
}

export default CustomersHeader;
