import React from 'react';
import { useSelector } from 'react-redux';

import QVDataTable from '../../common/components/QVDataTable';

import { institutionsStatisticsTableColumns } from './InstitutionsStatisticsConfig'

import {
    selectInstitutionsStatisticsData,
    selectIsLoading,
} from './store/institutionsStatisticsSlice';

const InstitutionsStatisticsTable = () => {

    const institutionsStatisticsData = useSelector(selectInstitutionsStatisticsData);
    const isLoading = useSelector(selectIsLoading);

    return (
        <QVDataTable
            title=""
            columns={institutionsStatisticsTableColumns}
            data={institutionsStatisticsData}
            isLoading={isLoading}
            noDataToShowText=""
            pagination={false}
        />
    );
}

export default InstitutionsStatisticsTable;