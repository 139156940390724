import React from 'react';
import { useSelector } from 'react-redux';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import QVDataTable from '../../common/components/QVDataTable';

import { articlesUsageStatisticsTableColumns } from './ArticlesUsageStatisticsConfig'

import {
    selectArticlesUsageStatisticsData,
    selectIsLoading,
    selectFrom,
    selectTo
} from './store/articlesUsageStatisticsSlice';

const IntervalColumnHeader = () => {
    const from = useSelector(selectFrom);
    const to = useSelector(selectTo);

    return (
        <div className='interval_header'>
            <span>{from}</span>
            <span>{to}</span>
        </div>
    );
}

const ArticlesIntervalCell = ({ row }) => {
    if(Object.keys(row.usage).length === 0) {
        return <div className='interval_chart'></div>;
    }

    const dataForChart = Object.values(row.usage);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const date = new Date(label);
            const formattedDate = `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`;
            return (
                <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #cccccc', fontSize: "12px", borderRadius: '5px' }}>
                    <p>{formattedDate}</p>
                    <p>{`${payload[0].value} Ausleihstunden `}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className='interval_chart'>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    data={dataForChart}
                    margin={{ top: 5, right: 20, left: 20, bottom: -0.5 }}
                >
                    <XAxis dataKey="date" ticks={[' ']} height={1} />
                    <YAxis type="number" domain={[0, 'dataMax + 1']} hide />
                    <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
                    <Area type="monotone" dataKey="hours" stroke="#70c1b3" fill="#70c1b3" isAnimationActive={false} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

// change the header of the interval column
articlesUsageStatisticsTableColumns[2].name = <IntervalColumnHeader />;

// change the cell of the interval column
articlesUsageStatisticsTableColumns[2].cell = row => <ArticlesIntervalCell row={row} />;

const ArticlesUsageStatisticsTable = () => {

    const articlesUsageStatisticsData = useSelector(selectArticlesUsageStatisticsData);
    const isLoading = useSelector(selectIsLoading);

    return (
        <QVDataTable
            title=""
            columns={articlesUsageStatisticsTableColumns}
            data={articlesUsageStatisticsData}
            isLoading={isLoading}
            noDataToShowText=""
            pagination={false}
        />
    );
}

export default ArticlesUsageStatisticsTable;