import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../common/scss/common.scss";
import "./SettingsPriceApp.scss";

import {
    getSettingsPrice, getCostCentersData,
    selectEnablePrices, selectCostCenterInCheckout,
    setSearchTerm, setSettingsPrice, setEnablePrices, setCostCenterInCheckout
} from "./store/settingsPriceSlice";

import lang from '../common/lang/de.json'

import PageTitle from '../common/components/PageTitle'

import SettingsPriceDataTableHeader from "./SettingsPriceDataTableHeader";
import SettingsPriceDataTable from "./SettingsPriceDataTable";

import ActionButton from '../common/components/ActionButton'
import CheckBox from '../common/components/CheckBox'

const SettingsPriceApp = () => {
    const dispatch = useDispatch();

    const enablePrices = useSelector(selectEnablePrices);
    const costCenterInCheckout = useSelector(selectCostCenterInCheckout);

    const disableCostCenterInCheckout = !enablePrices;

    useEffect(() => {
        dispatch(getSettingsPrice());
        dispatch(getCostCentersData());
    }, []);

    const onChangeEnablePrices = () => {
        const newEnablePrices = !enablePrices;
        dispatch(setEnablePrices(newEnablePrices));

        if (!newEnablePrices) {
            dispatch(setCostCenterInCheckout(false));
        }
    }

    const onChangeCostCenterInCheckout = () => {
        dispatch(setCostCenterInCheckout(!costCenterInCheckout));
    }

    // save settings, that calls the slice
    const saveSettings = () => {
        dispatch(setSettingsPrice({ enablePrices, costCenterInCheckout }));
    }

    const doSearch = (searchTerm) => {
        dispatch(setSearchTerm(searchTerm));
        dispatch(getCostCentersData());
    }

    return (
        <div id="settings_prices_app_content" className="col-one-full">
            <PageTitle title={lang.settings.price.page_title} />

            <div className="flex flex__col col-one-half">
                <ActionButton onClick={saveSettings} text={lang.save} />

                <div className="dash-block-container-sp" style={{ marginTop: "3rem" }}>
                    <div className="dash-block-header text-uppercase">{lang.settings.price.page_title}</div>

                    <div className="flex-column flex__wrap" style={{ padding: 10 }}>
                        <CheckBox htmlIdName="enablePrices" text={lang.settings.price.activate_prices_and_cost_center} checked={enablePrices} onChange={onChangeEnablePrices} />
                        <CheckBox htmlIdName="costCenterInCheckout" text={lang.settings.price.cost_center_in_checkout} disabled={disableCostCenterInCheckout} checked={costCenterInCheckout} onChange={onChangeCostCenterInCheckout} />
                    </div>
                </div>
            </div>

            <div className="data-table-block dash-block-container-sp data-table-block-extended ">
                <SettingsPriceDataTableHeader onDoSearch={doSearch} />

                <div className="table_wrapper">
                    <SettingsPriceDataTable />
                </div>
            </div>
        </div>
    );
}

export default SettingsPriceApp;