import React, { useState, useCallback } from 'react';
// import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
const debounce = require('lodash.debounce');

import RentalHistoryApi from "../../rental_history/api/RentalHistoryApi";

import "../scss/AutoSuggest.scss";

const AutoSuggest = (props) => {
    const [val, setVal] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    // Filter logic
    const getSuggestions = async value => {
        const response = await RentalHistoryApi.getSuggestions(props.filter.column, value);
        if (response.error) {
            setSuggestions([]);
        } else {
            setSuggestions(response.suggestions);
        }
    };

    // Trigger suggestions
    const getSuggestionValue = suggestion => suggestion.value;

    // Render Each Option
    const renderSuggestion = suggestion => (
        <div className="suggestion-item"> {suggestion.value} </div>
    );

    // OnChange event handler
    const onChange = (event, { newValue }) => {
        setVal(newValue);
    };

    // OnBlur event handler - close the autosuggest popup
    const onBlur = (event) => {
        props.onBlur();
    };

    const debouncedLoadSuggestions = useCallback(
        debounce(
            newVal => { 
                if(newVal.length >= 3) {// Load suggestions only if at least 3 chars have been enterd
                    getSuggestions(newVal)
                } else {
                    setSuggestions([]);
                }
            },
            750 // 750ms is the debouncing delay
        ),
        [] // Create debounced load suggestions function only once
    ); 

    // Suggestion rerender when user types
    const onSuggestionsFetchRequested = ({ value }) => {
        debouncedLoadSuggestions(value);
    };

    // Triggered on clear
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    // Option props
    const inputProps = {
        placeholder: props.lang.placeholders[props.filter.column],
        value: val,
        onChange: onChange,
        onBlur: onBlur,
        autoFocus: true
    };
    
    const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        props.onSuggestionSelected(suggestion);
    }

    return (
        <Autosuggest
                suggestions={suggestions ?? []}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
    );
}

AutoSuggest.propTypes = {
    // title: PropTypes.string.isRequired,
    // okButtonLabel: PropTypes.string,
    // okButtonClass: PropTypes.string,
    // children: PropTypes.node,
    // showButtons: PropTypes.bool,
    // onOkClicked: PropTypes.func,
    // onCancelClicked: PropTypes.func.isRequired
}

AutoSuggest.defaultProps = {
    // showButtons: true
};

export default AutoSuggest;