/* global moment */

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import classNames from "classnames";

import Popup from "../../common/components/Popup";
import ActionButton from './../../common/components/ActionButton';

import "../../common/scss/popups.scss";
import handout_articles_img from './../../../../css/images/handout_articles.png';
import return_articles_img from './../../../../css/images/return_articles.png';

import { SCAN_OPTION_HANDOUT, SCAN_OPTION_RETURN } from "../ScanOption";

import lang from "../../common/lang/de.json";

import {
    selectReservationId,
    selectReservationHeader,
    selectReservationArticles,
    getReservation
} from '../store/reservationSlice';

import { areAllArticlesHandedout } from "././ArticlesTableEntry/HandoutsReturnsUtils";
import { isReservationHandedOut, isReservationFullyReturned } from "./../ReservationStatus";

import ReservationApi from "../api/ReservationApi";

const ScanArticlesPopup = (props) => {
    const dispatch = useDispatch();

    const reservationId = useSelector(selectReservationId);
    const reservationHeader = useSelector(selectReservationHeader);
    const articles = useSelector(selectReservationArticles);
    
    const [showQRCode, setShowQRCode] = useState(false);
    const [qrCodeBase64, setQrCodeBase64] = useState("");

    const optionChoosen = async (choosenOption) => {
        
        const response = await ReservationApi.getScanArticlesQRCode(reservationId, choosenOption);
        const qrCodeBase64 = response["qr_code"];

        setQrCodeBase64(qrCodeBase64);
        setShowQRCode(true);

    }

    const renderScanOptions = () => {

        let reservationHandedOutStatus = reservationHeader.handed_out;
        let reservationReturnedStatus = reservationHeader.all_returned;

        const atLeastOneArticleIsHandedout = isReservationHandedOut(reservationHandedOutStatus);
        const allArticlesAreReturned = isReservationFullyReturned(reservationReturnedStatus);

        const allArticlesAreHandedout = areAllArticlesHandedout(articles);
        const isHandoutOptionDisabled = allArticlesAreHandedout;

        let errorMessageReturnNotPossible = false;
        if(allArticlesAreReturned) {
            errorMessageReturnNotPossible = lang.reservation.disabled_return_all_returned;

        } else if(!atLeastOneArticleIsHandedout) {
            errorMessageReturnNotPossible = lang.reservation.disabled_return_no_handouts;

        }

        const isReturnOptionDisabled = (errorMessageReturnNotPossible !== false);

        const cssClassesForHandoutOption = classNames("option", { disabled: isHandoutOptionDisabled });
        const cssClassesForReturnOption = classNames("option", { disabled: isReturnOptionDisabled });

        return (
            <>
                <div className="option_wrapper">
                    <div className={cssClassesForHandoutOption} onClick={() => !isHandoutOptionDisabled && optionChoosen(SCAN_OPTION_HANDOUT)}>
                        <img src={handout_articles_img} />
                        <span>{lang.reservation.articles} <b>{lang.reservation.handout}</b></span>
                    </div>

                    {allArticlesAreHandedout &&
                        <div className="message">
                            {lang.reservation.disabled_handout_all_handedout}
                        </div>
                    }
                </div>

                <div className="option_wrapper">
                    <div className={cssClassesForReturnOption} onClick={() => !isReturnOptionDisabled && optionChoosen(SCAN_OPTION_RETURN)}>
                        <img src={return_articles_img} />
                        <span>{lang.reservation.articles} <b>{lang.reservation.return}</b></span>
                    </div>

                    {errorMessageReturnNotPossible &&
                        <div className="message">
                            {errorMessageReturnNotPossible}
                        </div>
                    }
                </div>
            </>
        );
    }

    const onCloseAndRefresh = () => {
        dispatch(getReservation());
        props.onCancelClicked();
    }

    const renderQRCode = () => {
        const validUntil = moment()
            .add(2, "minutes") // this value should be the same as on the server ScanArticlesQRCode.php
            .add(59, "seconds").startOf('minute'); // for rounding to the next minute

        return (
            <div className="qr_code_holder">
                <div className="qr_code_info_header">
                    {lang.reservation.scan_articles_per_app_1}&nbsp;<b>{lang.reservation.scan_articles_per_app_2}</b>&nbsp;{lang.reservation.scan_articles_per_app_3}
                </div>

                <div className="qr_code">
                    <img src={qrCodeBase64} />
                </div>

                <div className="qr_code_info_footer">
                    {lang.reservation.scan_articles_per_app_open_app}
                    <br /><br />
                    {lang.reservation.qrcode_valid_until}{validUntil.format("HH:mm")}
                </div>

                <div className="close_and_refresh">
                    <ActionButton onClick={onCloseAndRefresh} text={lang.reservation.close_scan_popup}  />
                </div>
            </div>
        );
    }
    
    let popupContent = showQRCode ? renderQRCode() : renderScanOptions();
    
    return (
        <Popup title={lang.reservation.scan_articles} showButtons={false} onCancelClicked={props.onCancelClicked}>

            <div className="scan_articles">
                {popupContent}
            </div>
        </Popup>
    )
}

ScanArticlesPopup.propTypes = {
    onCancelClicked: PropTypes.func.isRequired,
}

export default ScanArticlesPopup;