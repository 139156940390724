/* global moment */

export const getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
}

export const formatString = (s, ...params) => {
    return s.replace(/{(\d+)}/g, function(match, number) { 
        return typeof params[number] != 'undefined' ? params[number] : match;
    });
}

export const convertDateForServer = (uiDate) => {
    const date = moment(uiDate, "DD.MM.YYYY HH:mm");
    return date.format("YYYY-MM-DDTHH:mm");
}

export const momentDateFromUIFormat = (uiDate) => {
    return moment(uiDate, "DD.MM.YYYY HH:mm");
}

export const decodeHtmlEntities = (text) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
};
