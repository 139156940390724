import React from "react";
import "../../common/scss/common.scss";
import "./ArticlesUsageStatisticsApp.scss";

import { articlesUsageStatisticsConfigLang } from './ArticlesUsageStatisticsConfig'

import PageTitle from '../../common/components/PageTitle'

import ArticlesUsageStatisticsTableHeader from "./ArticlesUsageStatisticsHeader";
import ArticlesUsageStatisticsTable from "./ArticlesUsageStatisticsTable";

const ArticlesUsageStatisticsApp = () => {
    return (
        <div id="articles_usage_statistics_app_content" className="col-one-full">
            <PageTitle title={articlesUsageStatisticsConfigLang.page_title} />

            <div className="flex flex__col">
                <div className="data-table-block dash-block-container-sp data-table-block-extended ">
                    <ArticlesUsageStatisticsTableHeader />

                    <div className="table_wrapper">
                        <ArticlesUsageStatisticsTable />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ArticlesUsageStatisticsApp;