import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import "../common/scss/common.scss";
import "./ImportInstitutionsApp.scss";

import {
    getInstitutionsSettings, getInstitutionsData,
    setSearchTerm
} from "./store/importInstitutionsSlice";

import lang from '../common/lang/de.json'

import PageTitle from '../common/components/PageTitle'

import ImportInstitutionsDataTableHeader from "./ImportInstitutionsDataTableHeader";
import ImportInstitutionsDataTable from "./ImportInstitutionsDataTable";

const ImportInstitutionsApp = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getInstitutionsSettings());
        dispatch(getInstitutionsData());
    }, []);

    const doSearch = (searchTerm) => {
        dispatch(setSearchTerm(searchTerm));
        dispatch(getInstitutionsData());
    }

    return (
        <div id="import_institutions_app_content" className="col-one-full">
            <PageTitle title={lang.settings.institutions.page_title} />

            <div className="data-table-block dash-block-container-sp data-table-block-extended ">
                <ImportInstitutionsDataTableHeader onDoSearch={doSearch} />

                <div className="table_wrapper">
                    <ImportInstitutionsDataTable />
                </div>
            </div>
        </div>
    );
}

export default ImportInstitutionsApp;