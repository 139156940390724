import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Popup from '../common/components/Popup';

import lang from '../common/lang/de.json';

function PopupAddInstitution({ onAddInstitution, onClose }) {
    const [formData, setFormData] = useState({
        name: '',
        additional_info: '',
        contact_person: '',
        email: '',
        street: '',
        postal_code_city: '',
        phone: '',
        website: ''
    });

    const [errors, setErrors] = useState({
        name: false,
        additional_info: false,
        contact_person: false,
        email: false,
        street: false,
        postal_code_city: false,
        phone: false,
        website: false
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));

        if (errors[name]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: false
            }));
        }
    };

    const handleSubmit = () => {
        let hasErrors = false;
        const newErrors = {};

        Object.keys(formData).forEach(key => {
            if ((key === 'name' || key === 'email') && !formData[key]) {
                newErrors[key] = true;
                hasErrors = true;
            } else {
                newErrors[key] = false;
            }
        });

        setErrors(newErrors);

        if (!hasErrors) {
            onAddInstitution(formData);
            onClose();
        }
    };

    return (
        <Popup
            title={lang.add_institution_popup.title}
            showOkButton={true}
            className="add_institution_popup"
            onOkClicked={handleSubmit}
            onCancelClicked={onClose}
        >
            <form style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.name}<span className="required">*</span></span></label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        required
                        onChange={handleChange}
                        style={{ borderColor: errors.name ? 'red' : '', flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.additional_info}</span></label>
                    <input
                        type="text"
                        name="additional_info"
                        value={formData.additional_info}
                        onChange={handleChange}
                        style={{ borderColor: errors.additional_info ? 'red' : '', flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.contact_person}</span></label>
                    <input
                        type="text"
                        name="contact_person"
                        value={formData.contact_person}
                        onChange={handleChange}
                        style={{ borderColor: errors.contact_person ? 'red' : '', flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.email}<span className="required">*</span></span></label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        style={{ borderColor: errors.email ? 'red' : '', flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.street}</span></label>
                    <input
                        type="text"
                        name="street"
                        value={formData.street}
                        onChange={handleChange}
                        style={{ borderColor: errors.street ? 'red' : '', flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.postal_code_city}</span></label>
                    <input
                        type="text"
                        name="postal_code_city"
                        value={formData.postal_code_city}
                        onChange={handleChange}
                        style={{ borderColor: errors.postal_code_city ? 'red' : '', flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.phone}</span></label>
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        style={{ borderColor: errors.phone ? 'red' : '', flex: 1 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '1rem', width: '200px' }}><span>{lang.add_institution_popup.labels.website}</span></label>
                    <input
                        type="text"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                        style={{ borderColor: errors.website ? 'red' : '', flex: 1 }}
                    />
                </div>
            </form>
        </Popup>
    );
}

PopupAddInstitution.propTypes = {
    onAddInstitution: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default PopupAddInstitution;
