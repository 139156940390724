import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import ActionButton from './ActionButton';

const FileSelectButton = ({ label, allowedFileExtensions, onFileSelected }) => {
    const fileInputRef = useRef(null);

    const handleFileInput = (event) => {
        const file = event.target.files[0];
        if (file) {
            onFileSelected(file);
            event.target.value = ''
        }
    };

    const acceptedFileExtensions = allowedFileExtensions ? allowedFileExtensions.join(',') : '';

    return (
        <div>
            <ActionButton onClick={() => fileInputRef.current.click()} text={label} />

            <input
                style={{ display: 'none' }}
                type="file"
                ref={fileInputRef}
                onChange={handleFileInput}
                accept={acceptedFileExtensions}
            />
        </div>
    );
};

FileSelectButton.propTypes = {
    label: PropTypes.string.isRequired,
    allowedFileExtensions: PropTypes.arrayOf(PropTypes.string),
    onFileSelected: PropTypes.func.isRequired,
};

export default FileSelectButton;
