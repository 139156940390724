import { client } from '../../common/api/client';

function getAvailabilityCheckApiUrl() {
    return "/components/AvailabilityCheck/ajax/ajax.php";
}

function getSubarticleAvailabilityCheckApiUrl() {
    return "/components/ArticleNumbers/ajax/ajax.php";
}

class ArticleAvailabilityApi {

    static check(contract_id, start_date, end_date, article_id, count, block_days_before, block_days_after) {
        
        return client.post(getAvailabilityCheckApiUrl(),
            {
                contract_id, start_date, end_date,
                article_id, count,
                block_days_before, block_days_after,
                method: 'isArticleAvailable'
            },
            {
                formDataPost: true
            }
        );
    }

    static checkSubarticle(contract_id, start_date, end_date, subarticle_id, block_days_before, block_days_after) {
        return client.post(getSubarticleAvailabilityCheckApiUrl(),
            {
                contract_id, start_date, end_date,
                subarticle_id,
                block_days_before, block_days_after,
                method: 'isSubarticleAvailable'
            },
            {
                formDataPost: true
            }
        );
    }
}

export default ArticleAvailabilityApi;