import React from 'react';
import { useSelector } from 'react-redux';

import QVDataTable from '../../common/components/QVDataTable';

import { costCentersStatisticsTableColumns } from './CostCentersStatisticsConfig'

import {
    selectCostCentersStatisticsData,
    selectIsLoading
} from './store/costCentersStatisticsSlice';

const CostCentersStatisticsTable = (props) => {
    
    const costCentersStatisticsData = useSelector(selectCostCentersStatisticsData);
    const isLoading = useSelector(selectIsLoading);
    
	return (
        <QVDataTable
            title=""
			columns={costCentersStatisticsTableColumns}
			data={costCentersStatisticsData}
			isLoading={isLoading}
            noDataToShowText=""
            pagination={false}
		/>
	);
}

export default CostCentersStatisticsTable;