import React from "react";
import lang from "../common/lang/de.json";
import SvgButton from "../common/components/SvgButton";

export const articlesConfigLang = lang.articles;

export const articlesTableColumns = [
    {
        name: articlesConfigLang.data_table.columns_names.name,
        // eslint-disable-next-line react/display-name
        cell: row => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={row.img_url} alt="article" style={{ maxHeight: '35px', maxWidth: '35px', marginRight: '10px' }} />
                <span dangerouslySetInnerHTML={{ __html: row.name }}></span>
            </div>
        ),
        width: "350px",
        sortable: true,
        sortField: 'name',
    },
    {
        name: articlesConfigLang.data_table.columns_names.number,
        selector: row => row.gnr,
        width: "100px",
        sortable: true,
        sortField: 'gnr',
    },
    {
        name: articlesConfigLang.data_table.columns_names.description,
        // eslint-disable-next-line react/display-name
        cell: row => <div style={{maxHeight: "60px", overflow: "hidden"}} dangerouslySetInnerHTML={{ __html: row.beschr }} />,
        minWidth: "350px",
        sortable: true,
        sortField: 'beschr',
    },
    {
        name: articlesConfigLang.data_table.columns_names.minimum,
        selector: row => row.lager_min,
        width: "85px",
        sortable: true,
        sortField: 'lager_min',
        right: true
    },
    {
        name: articlesConfigLang.data_table.columns_names.count,
        selector: row => row.lager,
        width: "85px", 
        sortable: true,
        sortField: 'lager',
        right: true
    },
    {
        name: articlesConfigLang.data_table.columns_names.warehouse_name,
        selector: row => row.location,
        width: "120px",
        sortable: true,
        sortField: 'location',
    },
    {
        name: articlesConfigLang.data_table.columns_names.action,
        // eslint-disable-next-line react/display-name
        cell: row => (
            <SvgButton
                svgRef="icon-pen"
                className="add-button"
                onClick={() =>  window.location.href = `/index.php?page=4-1_Artikel_bearbeiten&edit_id=${row.id}`}
            />
        ),
        width: "50px",
        sortable: false,
    }
];
