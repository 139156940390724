/* global qvapp */
import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

import '../scss/tag-list.scss'

import TagItem from './TagItem';


const TagsList = (props) => {
    const cssClasses = classNames(["tags-list", props.className]);

    const buildTags = tag => {
        return <TagItem key={tag.column + ":" + tag.value.id} type={tag.column === 'customer' ? qvapp.i18n.t("label_lender") : props.lang[tag.column]} name={tag.value.value} onClick={ () => props.onClick(tag)} />
    }

    return (
        <div className={cssClasses}>
        { props.tags && props.tags.length > 0 && props.tags.map(buildTags)}
        </div>
    );
}

TagsList.propTypes = {
    className: PropTypes.string,
    tags: PropTypes.array.isRequired,
    lang: PropTypes.object.isRequired,

    onClick: PropTypes.func.isRequired
}

export default TagsList;