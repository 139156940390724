import React from 'react';
import { useSelector } from 'react-redux';

import {
    selectReservationArticles
} from '../store/reservationSlice';

import ArticlesTableEntry from "./ArticlesTableEntry/ArticlesTableEntry";
import ArticlesTableEntryWithSubarticles from './ArticlesTableEntry/ArticlesTableEntryWithSubarticles';

const ArticlesTableContent = () => {
    const articles = useSelector(selectReservationArticles);
    if(!articles) {
        return <></>;
    }

    const getKeyForArticle = (article) => {
        return `${article.a_id}_${article.recurring_id}_${article.recurrence_pos}`;
    }

    const renderArticle = (article) => {
        if(article.has_subarticles) {
            return (<ArticlesTableEntryWithSubarticles key={getKeyForArticle(article)} article={article} />)
        } else {
            return (<ArticlesTableEntry key={getKeyForArticle(article)} article={article} />)
        }
    }

    return (
        <div className="qv_table_content">
            {articles.map((article) =>
                renderArticle(article))
            }
        </div>
    );
}

export default ArticlesTableContent;
