import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import RentalHistoryApp from './RentalHistoryApp';

import rentalHistoryReducer, { setRowsOnPage, setPageNumber } from './store/rentalHistorySlice';

// import RentalHistoryApi from "./api/RentalHistoryApi";

// the interface between js app and react app
window.quickverleih.rentalHistoryApp = (function() {
    // if needed to interact with the react app, use this store
    let rentalHistoryStore = null;

    function init_rental_history() {

        rentalHistoryStore = configureStore({
            reducer: {
              rental_history: rentalHistoryReducer,
            }
        })

        if(process.env.NODE_ENV === 'development') {
            window.quickverleih.rentalHistoryApp.rental_history_store = rentalHistoryStore;
        }

        rentalHistoryStore.dispatch(setRowsOnPage(20));
        rentalHistoryStore.dispatch(setPageNumber(1));

        ReactDOM.render(
            <Provider store={rentalHistoryStore}>
                <RentalHistoryApp />
            </Provider>,
            document.getElementById("rental_history_app")
        );
    }

    return {
        init_rental_history
    };
})();