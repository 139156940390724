/* globals moment */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ShopSearchTermsStatisticsApi from "../api/ShopSearchTermsStatisticsApi";

import { convertDateForServer } from "../../../common/utils";

import lang from "../../../common/lang/de.json";

export const termsFilterOptions = [
    {value: "all_terms", label: lang.shop_search_terms_statistics.filter_all_terms},
    {value: "terms_without_found_articles", label: lang.shop_search_terms_statistics.filter_terms_wihtout_found_articles}
];

const initialState = {
    status: 'idle',
    error: null,

    termsFilter: termsFilterOptions[0],
    searchTerm: "",
    from: moment().minute(0).subtract(7, 'days').format("DD.MM.YYYY"),
    to: moment().minute(0).format("DD.MM.YYYY"),

    shopSearchTermsStatisticsData: [],
};


export const getShopSearchTermsStatisticsData = createAsyncThunk(
    'shop_search_terms_statistics/get_cost_centers_data',
    async (_noParams, { getState }) => {
        
        const termsFilter = getState().shop_search_terms_statistics.termsFilter.value;
        const searchTerm = getState().shop_search_terms_statistics.searchTerm;
        const from = getState().shop_search_terms_statistics.from;
        const to = getState().shop_search_terms_statistics.to;
        
        const response = await ShopSearchTermsStatisticsApi.getShopSearchTermsStatisticsData(termsFilter, searchTerm,
            convertDateForServer(from), convertDateForServer(to));

        return response;
    }
)

export const slice = createSlice({
    name: 'shop_search_terms_statistics',
    initialState,
    reducers: {
        setTermsFilter: (state, action) => {
            state.termsFilter = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setFromTo: (state, action) => {
            state.from = action.payload.from;
            state.to = action.payload.to;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getShopSearchTermsStatisticsData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getShopSearchTermsStatisticsData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.shopSearchTermsStatisticsData = action.payload.shop_search_terms_statistics_data;
            })
            .addCase(getShopSearchTermsStatisticsData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    },
});

export const selectIsLoading = state => state.shop_search_terms_statistics.status === "loading";
export const selectTermsFilter = state => state.shop_search_terms_statistics.termsFilter;
export const selectSearchTerm = state => state.shop_search_terms_statistics.searchTerm;
export const selectFrom = state => state.shop_search_terms_statistics.from;
export const selectTo = state => state.shop_search_terms_statistics.to;
export const selectShopSearchTermsStatisticsData = state => state.shop_search_terms_statistics.shopSearchTermsStatisticsData;

export const { setTermsFilter, setSearchTerm, setFromTo } = slice.actions;

export default slice.reducer;