import React from 'react';
import PropTypes from 'prop-types';

import "../scss/AutoSuggestPopup.scss";

import lang from "../../../common/lang/de.json"

import ActionButton from '../../../common/components/ActionButton';
import AutoSuggest from './AutoSuggest';

const AutoSuggestPopup = (props) => {
    return (
        <div className='autosuggest_popup_container'>
            <div className='popup_inner'>
                <div className="dash-block-header text-uppercase">{props.title}</div>

                <div className="popoup_content">
                    <AutoSuggest lang={props.lang} filter={props.filter} onSuggestionSelected={props.onSuggestionSelected} onBlur={props.onCancelClicked}/>
                </div>

                {props.showButtons &&
                    <div className="popup_buttons right_block">
                        <ActionButton onClick={props.onCancelClicked} text={lang.cancel} className="button text-uppercase secondary-button " />
                        {props.showOKButton && <ActionButton onClick={props.onOkClicked} text={props.okButtonLabel ?? lang.ok} className={"button text-uppercase "+props.okButtonClass ?? " "} /> }
                    </div>
                }

                {props.showCloseIcon && <div onClick={props.onCancelClicked}  className="close-poppup" /> }
            </div>
        </div>
    );
}

AutoSuggestPopup.propTypes = {
    title: PropTypes.string.isRequired,
    okButtonLabel: PropTypes.string,
    okButtonClass: PropTypes.string,
    children: PropTypes.node,
    showButtons: PropTypes.bool,
    showOKButton: PropTypes.bool,
    showCloseIcon: PropTypes.bool,
    onOkClicked: PropTypes.func,
    onCancelClicked: PropTypes.func.isRequired
}

AutoSuggestPopup.defaultProps = {
    showButtons: true
};

export default AutoSuggestPopup;