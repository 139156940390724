import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ReservationApi from "../api/ReservationApi";
import { prepareCategoriesData, prepareLocationsData } from "./reservationSliceUtils";

const initialState = {
    status: 'idle',
    error: null,

    customer: null, 
    articleTenantId: null, 
    start_date: null, 
    start_time: null, 
    end_date: null, 
    end_time: null, 
    filter: "", 
    specFilter: [], 
    locationId: 0,

    categories: [],
    locations: [],
    spezi: []
};

export const getCategories = createAsyncThunk(
    'articlesPopup/getCategories',
    async (_noParams, { getState }) => {
        const state = getState();
        const tenantId = selectCustomerTenantId(state);

        const response = await ReservationApi.getCategories(tenantId);
        return response;
    }
)

export const getSpeziForArticles = createAsyncThunk(
    'articlesPopup/getSpeziForArticles',
    async ({customerTenantId, articleTenantId, start_date, start_time, end_date, end_time, filter, specFilter, locationId, category}, { getState }) => {
        const response = await ReservationApi.getSpeziForArticles(customerTenantId, articleTenantId, start_date, start_time, end_date, end_time, filter, specFilter, locationId, category.value);
        return response;
    }
)

export const getLocationsForCustomer = createAsyncThunk(
    'articlesPopup/getLocationsForCustomer',
    async (_noParams, { getState }) => {
        const state = getState();
        // const tenantId = selectCustomerTenantId(state);
        const customer = selectCustomer(state);

        const response = await ReservationApi.getLocationsForCustomer(customer);
        return response;
    }
)

export const slice = createSlice({
    name: 'articlesPopup',
    initialState,

    reducers: {
        setArticlesPopupInitialData: (state, action) => {
            state.customer = action.payload.customer;
            state.articleTenantId = action.payload.articleTenantId;
            state.start_date = action.payload.start_date;
            state.start_time = action.payload.start_time;
            state.end_date = action.payload.end_date;
            state.end_time = action.payload.end_time;
            state.filter = action.payload.filter;
            state.specFilter = action.payload.specFilter;
            state.locationId = action.payload.locationId;
        },
        setLocationId: (state, action) => {
            state.locationId = action.payload;
        }
    },
    
    extraReducers: {
        [getCategories.pending]: (state) => {
            state.status = 'loading';
        },
        [getCategories.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.categories = prepareCategoriesData(action.payload.categories);
        },
        [getCategories.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },

        [getSpeziForArticles.pending]: (state) => {
            state.status = 'loading';
        },
        [getSpeziForArticles.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.spezi = action.payload.attributes;
        },
        [getSpeziForArticles.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },

        // LOCATIONS FOR CUSTOMER(BASED ON CUSTOMER TENANT ID)
        [getLocationsForCustomer.pending]: (state) => {
            state.status = 'loading';
        },
        [getLocationsForCustomer.fulfilled]: (state, action) => {
            // console.log("Received locations for customer: ", action.payload);
            state.status = 'succeeded';
            state.locations = prepareLocationsData(action.payload.locations);
        },
        [getLocationsForCustomer.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
    }
});

export const { setArticlesPopupInitialData, setLocationId } = slice.actions;

export const selectCategories = state => state.articlesPopup.categories;
export const selectCustomerTenantId = state => (state.articlesPopup.customer && state.articlesPopup.customer.tenant_id ? state.articlesPopup.customer.tenant_id : 10000);
export const selectArticleTenantId = state => state.articlesPopup.articleTenantId;
export const selectStartDate = state => state.articlesPopup.start_date;
export const selectStartTime = state => state.articlesPopup.start_time;
export const selectEndDate = state => state.articlesPopup.end_date;
export const selectEndTime = state => state.articlesPopup.end_time;
export const selectFilter = state => state.articlesPopup.filter;
export const selectSpecFilter = state => state.articlesPopup.specFilter;
export const selectLocationId = state => state.articlesPopup.locationId;
export const selectSpezi = state => state.articlesPopup.spezi;
export const selectLocations = state => state.articlesPopup.locations;
export const selectCustomer = state => state.articlesPopup.customer;
export const selectCustomerLocations = state => (state.articlesPopup.customer && state.articlesPopup.customer.locations ? state.articlesPopup.customer.locations : []);

export default slice.reducer;