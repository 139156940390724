import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import QVDataTable from '../common/components/QVDataTable';

import { articlesTableColumns } from './ArticlesConfig'

import {
    getArticlesData,
    setPageNumber,
    setRowsOnPage,
    selectArticlesData,
    selectIsLoading,
    selectTotalRows,
    selectRowsOnPage,
    setSortColumn,
    setSortDirection
} from './store/articlesSlice';

const ArticlesTable = () => {
    const dispatch = useDispatch();
    const articlesData = useSelector(selectArticlesData);
    const isLoading = useSelector(selectIsLoading);
    const totalRows = useSelector(selectTotalRows);
    const rowsPerPage = useSelector(selectRowsOnPage);

    const handlePageChange = page => {
        dispatch(setPageNumber(page));
        dispatch(getArticlesData());
    };

    const handleSort = (column, sortDirection) => {
        dispatch(setSortColumn(column.sortField));
        dispatch(setSortDirection(sortDirection));
        dispatch(getArticlesData());
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(setRowsOnPage(newPerPage));
        dispatch(setPageNumber(page));
        dispatch(getArticlesData());
    };
    
	return (
        <QVDataTable
            title=""
			columns={articlesTableColumns}
			data={articlesData}
            isLoading={isLoading}
			paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onSort={handleSort}
            onChangePage={handlePageChange}
            paginationPerPage={rowsPerPage}
            noDataToShowText=""
            defaultSortFieldId={2}
		/>
	);
}

export default ArticlesTable;
