
import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

const SvgButton = (props) => {
    let cssClasses = classNames(["svg_button", props.className]);

    return (
        <div className={cssClasses} onClick={props.onClick}>
            {props.svgRef && props.svgRef != "" &&
                <svg className="svg_icon">
                    <use xlinkHref={"#" + props.svgRef}></use>
                </svg>
            }

            {props.svgFile && props.svgFile != "" &&
                <img className="svg_icon" src={props.svgFile} />
            }

            {props.textRight && props.textRight != "" &&
                <>
                &nbsp;&nbsp;
                <span className="action_text">{props.textRight}</span>
                </>
            }
        </div>
    );
}

// svgRef is an id used in the file css/images/icons.svg
SvgButton.propTypes = {
    className: PropTypes.string,
    svgFile: PropTypes.string,
    svgRef: PropTypes.string,
    textRight: PropTypes.string,

    onClick: PropTypes.func.isRequired
}

export default SvgButton;