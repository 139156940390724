import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useDispatch } from 'react-redux';

import CountAndDateSection from './CountAndDateSection';

import { convertDateForServer, getRandomArbitrary } from "../../../common/utils";
import { getMaxAllowedReturnCount } from "./HandoutsReturnsUtils"

import {
    addNewReturn,
    changeReturn
} from '../../store/reservationSlice';

const ReturnsSection = (props) => {
    const {handouts_count, returns} = props;

    const dispatch = useDispatch();

    useEffect(() => {
        // setting the key for new handouts to a random value, otherwise the entry will not be refreshed
        const randomKey = getRandomArbitrary(60000, 70000);
        setKeyForNewReturnEntry(randomKey);
    }, [returns]);

    const [keyForNewReturnEntry, setKeyForNewReturnEntry] = useState(0);

    const onAddNewReturn = (count, day) => {
        dispatch(addNewReturn({article_id: props.article_id, subarticle_id: props.subarticle_id, count, day: convertDateForServer(day),
           recurring_id: props.recurring_id, recurrence_pos: props.recurrence_pos}));
    }

    const onReturnChanged = (returnId, count, day) => {
        dispatch(changeReturn({return_id: returnId, article_id: props.article_id, subarticle_id: props.subarticle_id, count, day: convertDateForServer(day),
            recurring_id: props.recurring_id, recurrence_pos: props.recurrence_pos}));
    }

    const renderArticleReturn = (article_return) => {
        // one entry of returns can allow a max count to be returned out of article's handed out count minus the sum of the already returned articles (not including this entry)
        const maxAllowedReturnCount = getMaxAllowedReturnCount(returns, handouts_count, article_return);

        return  <CountAndDateSection className="return_section" childredClassNamePrefix="return" key={article_return.id} maxCount={maxAllowedReturnCount} count={article_return.quantity} date={article_return.date} 
            readOnly={!props.isAvailable || props.readOnly} onCountAndDateChanged={(count, day) => onReturnChanged(article_return.id, count, day)} />
    }

    // this is the count that is allow for a new return: article's handed out count minus the sum of the already returned articles
    const maxAllowedCountForNextReturn = getMaxAllowedReturnCount(returns, handouts_count);

    // if articles have been handed out and no more articles can be returned -> mark section as all returned
    let cssClasses = classNames("return_sections_container", {all_returned:( maxAllowedCountForNextReturn == 0 && handouts_count > 0)});

    return (
        <div className={cssClasses}>
            {props.isReservation &&
                <>
                {returns && returns.map(article_return =>
                    renderArticleReturn(article_return))
                }

                {/* add an empty session to be able to add returns */}
                {maxAllowedCountForNextReturn > 0 &&
                    <CountAndDateSection className="return_section" childredClassNamePrefix="return" maxCount={maxAllowedCountForNextReturn} key={keyForNewReturnEntry} count="" date="" readOnly={!props.isAvailable || props.readOnly}
                        onCountAndDateChanged={onAddNewReturn}/>
                }
                </>
            }
        </div>
    );
}

ReturnsSection.propTypes = {
    handouts_count: PropTypes.number.isRequired,
    returns: PropTypes.array,
    
    article_id: PropTypes.string.isRequired,
    subarticle_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    recurring_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    recurrence_pos: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,

    isReservation: PropTypes.bool,

    readOnly: PropTypes.bool,
    isAvailable: PropTypes.bool
}

export default ReturnsSection;