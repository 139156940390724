import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import "./../scss/popup.scss";

import lang from "../lang/de.json";

import ActionButton from './ActionButton';

const Popup = (props) => {
    let cssClasses = classNames(["popup_container", props.className]);

    return (
        <div className={cssClasses}>
            <div className='popup_inner'>
                <div className="dash-block-header">{props.title}</div>

                <div className="popoup_content">
                    {props.children}
                </div>

                {props.showButtons &&
                    <div className="popup_buttons">
                        {props.showOkButton && 
                            <ActionButton onClick={props.onOkClicked} text={props.okButtonLabel ?? lang.ok} className={props.okButtonClass ?? ""} />
                        }
                        {props.showCancelButton && 
                            <ActionButton onClick={props.onCancelClicked} text={lang.cancel} className="right_block green_border" />
                        }
                    </div>
                }

                <div onClick={props.onCancelClicked}  className="close-poppup" />
            </div>
        </div>
    );
}

Popup.propTypes = {
    className: PropTypes.string,
    
    title: PropTypes.string.isRequired,
    okButtonLabel: PropTypes.string,
    okButtonClass: PropTypes.string,
    children: PropTypes.node,
    showButtons: PropTypes.bool,
    showOkButton: PropTypes.bool,
    showCancelButton: PropTypes.bool,
    onOkClicked: PropTypes.func,
    onCancelClicked: PropTypes.func.isRequired
}

Popup.defaultProps = {
    showButtons: true,
    showOkButton: true,
    showCancelButton: true,
};

export default Popup;