import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import lang from "../../../common/lang/de.json";
import SvgButton from '../../../common/components/SvgButton';
import ReplaceSvg from "../../../../../css/images/replace.svg";

import {
    replaceSubarticleInContract,
    removeArticleNotAvailable
} from '../../store/reservationSlice';

import SubarticlesPopup from './SubarticlesPopup';

const ReplaceSubarticleButton = (props) => {
    const dispatch = useDispatch();

    const [showPopup, setShowPopup] = useState(false);
    const { article, subarticle, lastUsedFromInAvailabilityCheck, lastUsedToInAvailabilityCheck } = props;
    
    const onReplaceSubarticleConfirmed = (newSubarticleId, fromDate, toDate) => {
        setShowPopup(false);

        let subarticleId = subarticle.subarticle_id;
        let recurringId = subarticle.recurring_id;
        let recurrencePos = subarticle.recurrence_pos;

        // ANDY - 14.07.2022 - remove the article from unavavilable articles array when replaced(mark as available)
        dispatch(removeArticleNotAvailable({
            "articleId": article.a_id,
            "subarticleId": subarticleId
        }));

        dispatch(replaceSubarticleInContract({
            article_id: article.a_id,
            remove_subarticle_id: subarticleId,
            recurring_id: recurringId,
            recurring_pos: recurrencePos,
            new_subarticle_id: newSubarticleId,
            from: fromDate,
            to: toDate
        }));
    }

    return (
        <>
            <div className='replace_button'>
                <SvgButton svgFile={ReplaceSvg} onClick={() => setShowPopup(true)} />
            </div>

            {showPopup &&
                <SubarticlesPopup title={lang.reservation.replace_add_subarticle_popup.replace_subarticle_title} 
                    article={article} subarticle={subarticle} 
                    lastUsedFromInAvailabilityCheck={lastUsedFromInAvailabilityCheck}
                    lastUsedToInAvailabilityCheck={lastUsedToInAvailabilityCheck}
                    showOkButton={false} onActionConfirmed={onReplaceSubarticleConfirmed} onCancel={() => setShowPopup(false)} />
            }
        </>
    );
}

ReplaceSubarticleButton.propTypes = {
    article: PropTypes.object.isRequired,
    subarticle: PropTypes.object,
    lastUsedFromInAvailabilityCheck: PropTypes.string.isRequired,
    lastUsedToInAvailabilityCheck: PropTypes.string.isRequired,
}

export default ReplaceSubarticleButton;