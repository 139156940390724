import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import QVDataTable from '../common/components/QVDataTable';

import { customersTableColumns } from './CustomersConfig'

import {
    getCustomersData,
    setPageNumber,
    setRowsOnPage,
    selectCustomersData,
    selectIsLoading,
    selectTotalRows,
    selectRowsOnPage,
    selectInstitutionsEnabled,
    setSortField,
    setSortOrder
} from './store/customersSlice';

const CustomersTable = () => {
    const dispatch = useDispatch();
    const customersData = useSelector(selectCustomersData);
    const institutionsEnabled = useSelector(selectInstitutionsEnabled);
    const isLoading = useSelector(selectIsLoading);
    const totalRows = useSelector(selectTotalRows);
    const rowsPerPage = useSelector(selectRowsOnPage);

    const handlePageChange = page => {
        dispatch(setPageNumber(page));
        dispatch(getCustomersData());
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(setRowsOnPage(newPerPage));
        dispatch(setPageNumber(page));
        dispatch(getCustomersData());
    };

    const handleSort = (column, sortDirection) => {
        dispatch(setSortField(column.sortField));
        dispatch(setSortOrder(sortDirection));
        dispatch(getCustomersData());
    };
    
	return (
        <QVDataTable
            title=""
			columns={customersTableColumns(institutionsEnabled)}
			data={customersData}
            isLoading={isLoading}
			paginationTotalRows={totalRows}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
            paginationPerPage={rowsPerPage}
            noDataToShowText=""
            onSort={handleSort}
            defaultSortFieldId={2}
		/>
	);
}

export default CustomersTable;
