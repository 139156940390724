import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import ArticlesApp from './ArticlesApp';

import articlesReducer from './store/articlesSlice';

// the interface between js app and react app
window.quickverleih.articles_app = (function() {

    function init() {

        let articlesStore = configureStore({
            reducer: {
                articles: articlesReducer,
            }
        })

        ReactDOM.render(
            <Provider store={articlesStore}>
                <ArticlesApp />
            </Provider>,
            document.getElementById("articles_app")
        );
    }

    return {
        init
    };
})();
