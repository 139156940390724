import { client } from '../../../common/api/client';

const settingsBaseUrl = "/includes/reports/";
const baseApiUrl = settingsBaseUrl + "api/shop_search_terms_statistics.php?method=";

function getApiUrl(action) {
    return baseApiUrl + action;
}

class ShopSearchTermsStatisticsApi {

    static getShopSearchTermsStatisticsData(termsFilter, searchTerm, from, to) {
        return client.post(getApiUrl("getShopSearchTermsStatisticsData"), {
            termsFilter,
            searchTerm,
            from,
            to
        });
    }
}

export default ShopSearchTermsStatisticsApi;
