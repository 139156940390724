/* global moment */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import "./../scss/popup.scss";
import "../scss/FromToIntervalPopup.scss"

import Popup from "./Popup";
import FromToInterval from "../../reservation/Articles/ArticlesTableEntry/FromToInterval";

import { convertDateForServer } from '../utils';

const FromToIntervalPopup = (props) => {
    const [from, setFrom] = useState(props.from);
    const [to, setTo] = useState(props.to);

    const onOk = () => {
        props.onOk(convertDateForServer(from), convertDateForServer(to));
    }

    const onTimeIntervalChanged = (newFrom, newTo) => {
        const fromValue = moment(newFrom).format("DD.MM.YYYY HH:mm");
        const toValue =  moment(newTo).format("DD.MM.YYYY HH:mm");

        setFrom(fromValue);
        setTo(toValue);
    }

    return (
        <Popup title={props.title} showButtons={true} onOkClicked={onOk} onCancelClicked={props.onCancel} className="center_content">
            <div className="info" id="from_to_interval_popup_content">
                <FromToInterval article_id={"15000"} subarticle_id={"15000"} recurring_id={0} recurrence_pos={0}
                    from={from} to={to} onChange={onTimeIntervalChanged} readOnly={false}/>
            </div>
        </Popup>
    );
}

FromToIntervalPopup.propTypes = {
    title: PropTypes.string.isRequired,
    onOk: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
}

export default FromToIntervalPopup;