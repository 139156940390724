import lang from "../common/lang/de.json";

export const importInstitutionsLang = lang.settings.institutions;

export const costCenterTableColumns = [
    {
        name: importInstitutionsLang.data_table.columns_names.name,
        selector: row => row.name,
        width: "240px",
        minWidth: "230px",
        sortable: false
    },
    {
        name: importInstitutionsLang.data_table.columns_names.additional_info,
        selector: row => row.additional_info,
        width: "200px",
        minWidth: "200px",
        sortable: false
    },
    {
        name: importInstitutionsLang.data_table.columns_names.contact_person,
        selector: row => row.contact_person,
        minWidth: "90px",
        sortable: false
    },
    {
        name: importInstitutionsLang.data_table.columns_names.email,
        selector: row => row.email,
        minWidth: "290px",
        grow: 1,
        sortable: false
    },
    {
        name: importInstitutionsLang.data_table.columns_names.street,
        selector: row => row.street,
        minWidth: "100px",
        grow: 1,
        sortable: false
    },
    {
        name: importInstitutionsLang.data_table.columns_names.postal_code_city,
        selector: row => row.postal_code_city,
        minWidth: "100px",
        grow: 1,
        sortable: false
    },
    {
        name: importInstitutionsLang.data_table.columns_names.phone,
        selector: row => row.phone,
        minWidth: "100px",
        grow: 1,
        sortable: false
    },
    {
        name: importInstitutionsLang.data_table.columns_names.website,
        selector: row => row.website,
        minWidth: "200px",
        grow: 1,
        sortable: false
    },
];
