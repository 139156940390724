import React from 'react';

const PageTitle = (props) => {
    return (
        <div className="col-one-full flex flex__wrap">
            <h2 className="dashboard-title text-uppercase alpha secondary-ff">{props.title}</h2>
        </div>
    )
}

export default PageTitle;
