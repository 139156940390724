/* globals moment */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ArticlesUsageStatisticsApi from "../api/ArticlesUsageStatisticsApi";

import { convertDateForServer } from "../../../common/utils";

import lang from "../../../common/lang/de.json";

const initialState = {
    status: 'idle',
    error: null,

    selectedCategory: {value: "0", label: lang.all_categories},
    searchTerm: "",
    from: moment().minute(0).subtract(7, 'days').format("DD.MM.YYYY"),
    to: moment().minute(0).format("DD.MM.YYYY"),

    articlesUsageStatisticsData: [],

    categories: []
};


export const getArticlesUsageStatisticsData = createAsyncThunk(
    'article_usage_statistics/get_cost_centers_data',
    async (_noParams, { getState }) => {
        
        const selectedCategory = getState().article_usage_statistics.selectedCategory.value;
        const searchTerm = getState().article_usage_statistics.searchTerm;
        const from = getState().article_usage_statistics.from;
        const to = getState().article_usage_statistics.to;
        
        const response = await ArticlesUsageStatisticsApi.getArticlesUsageStatisticsData(selectedCategory, searchTerm,
            convertDateForServer(from), convertDateForServer(to));

        return response;
    }
)

export const getCategories = createAsyncThunk(
    'article_usage_statistics/getCategories',
    async (_noParams, { getState }) => {

        const response = await ArticlesUsageStatisticsApi.getCategories();
        return response;
    }
)

export const slice = createSlice({
    name: 'article_usage_statistics',
    initialState,
    reducers: {
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setFromTo: (state, action) => {
            state.from = action.payload.from;
            state.to = action.payload.to;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getArticlesUsageStatisticsData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getArticlesUsageStatisticsData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.articlesUsageStatisticsData = action.payload.article_usage_statistics_data;
            })
            .addCase(getArticlesUsageStatisticsData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = action.payload.categories;
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const selectIsLoading = state => state.article_usage_statistics.status === "loading";
export const selectSelectedCategory = state => state.article_usage_statistics.selectedCategory;
export const selectSearchTerm = state => state.article_usage_statistics.searchTerm;
export const selectFrom = state => state.article_usage_statistics.from;
export const selectTo = state => state.article_usage_statistics.to;
export const selectCategories = state => state.article_usage_statistics.categories;
export const selectArticlesUsageStatisticsData = state => state.article_usage_statistics.articlesUsageStatisticsData;

export const { setSelectedCategory, setSearchTerm, setFromTo } = slice.actions;

export default slice.reducer;