import React, { Component } from "react";
import { connect } from "react-redux";
import "../common/scss/common.scss";
import "./ReservationApp.scss";

import ReservationHeader from "./ReservationHeader";
import ReservationCustomer from "./ReservationCustomer";
import ReservationArticles from "./Articles/ReservationArticles";
import ReservationExtraPDFText from "./ReservationExtraPDFText";
import ReservationFooter from "./ReservationFooter";

import { getReservation } from "./store/reservationSlice";

class ReservationApp extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // eslint-disable-next-line react/prop-types
        this.props.getReservation();
    }

    render() {
        return (
            <div id="reservation_app_content" className="col-one-full">
                <ReservationHeader />

                <ReservationCustomer />
                
                <ReservationArticles />

                <ReservationExtraPDFText />

                <ReservationFooter />
            </div>
        );
    }
}

export default connect(null, { getReservation })(ReservationApp);
