/* globals moment */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SearchField from '../../common/components/SearchField';
import FromToInterval from '../../reservation/Articles/ArticlesTableEntry/FromToInterval';
import DropdownSelect from '../../common/components/DropdownSelect';

import { articlesUsageStatisticsConfigLang } from './ArticlesUsageStatisticsConfig'

import { getCategories, getArticlesUsageStatisticsData,
    selectCategories, selectSelectedCategory, selectSearchTerm, selectFrom, selectTo, 
    setSelectedCategory, setSearchTerm, setFromTo } from './store/articlesUsageStatisticsSlice';

import lang from "../../common/lang/de.json";

const ArticlesUsageStatisticsHeader = () => {
    const dispatch = useDispatch();

    const categories = useSelector(selectCategories);

    const selectedCategory = useSelector(selectSelectedCategory);
    const searchTerm = useSelector(selectSearchTerm);
    const from = useSelector(selectFrom);
    const to = useSelector(selectTo);

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    useEffect(() => {
        dispatch(getArticlesUsageStatisticsData());
    }, [selectedCategory, searchTerm, from, to]);

    const onCategoryChanged = (category) => {
        dispatch(setSelectedCategory(category));
    }

    const onDoSearch = (searchTerm) => {
        dispatch(setSearchTerm(searchTerm));
    }

    const onTimeIntervalChanged = (from, to) => {
        dispatch(
            setFromTo({
                from: moment(from).format("DD.MM.YYYY"),
                to: moment(to).format("DD.MM.YYYY")
        }   )
        );
    }

    return (
        <>
            <div className="header data-table-header dash-block-header flex flex__align--center">
                <div className="settings-price-header-table-header-title">{articlesUsageStatisticsConfigLang.data_table.title}</div>
            </div>

            <div className="header-content">
                <div className="header-content-entry-row" style={{ alignItems: "center" }}>
                    <FromToInterval article_id="0" subarticle_id="0" recurring_id="0" recurrence_pos="0" showToText={true}
                        dateOnly={true} setMin={false}
                        from={from} to={to} onChange={onTimeIntervalChanged} />
                </div>

                <div style={{ marginLeft: "auto", display: "flex" }}>
                    <DropdownSelect placeholder={lang.all_categories} value={selectedCategory} options={categories} onChange={onCategoryChanged} />
                    <SearchField onDoSearch={onDoSearch} searchTerm={searchTerm} />
                </div>
            </div>
        </>
    )
}

export default ArticlesUsageStatisticsHeader;
