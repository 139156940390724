import { client } from '../../../common/api/client';

const baseApiUrl = "/includes/reports/api/rental_history.php?method=";

function getApiUrl(action) {
    return baseApiUrl + action;
}

class RentalHistoryApi {

    static getRentalHistoryData(article_names, customer_names, rows_on_page, page_number, sort_field, sort_direction) {
        return client.post(getApiUrl("getRentalHistoryData"), {article_names, customer_names, rows_on_page, page_number, sort_field, sort_direction});
    }

    static getRentalHistoryTotalRows(article_names, customer_names) {
        return client.post(getApiUrl("getRentalHistoryTotalRows"), {article_names, customer_names});
    }

    static getSuggestions(type, value) {
        return client.post(getApiUrl("getSuggestions"), {type, value});
    }
}

export default RentalHistoryApi;
