import lang from "../common/lang/de.json";

export const settingsPriceLang = lang.settings.price;

export const costCenterTableColumns = [
    {
        name: settingsPriceLang.data_table.columns_names.number,
        selector: row => row.number,
        width: "200px",
        minWidth: "200px",
        sortable: false
    },
    {
        name: settingsPriceLang.data_table.columns_names.responsible,
        selector: row => row.responsible,
        width: "500px",
        minWidth: "500px",
        sortable: false
    },
    {
        name: settingsPriceLang.data_table.columns_names.number,
        selector: row => row.email,
        minWidth: "300px",
        grow: 1,
        sortable: false
    },
];
