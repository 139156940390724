

const TYPE_REQUEST = 2;
const TYPE_RESERVATION = 3;

export const isTypeRequest = (contract_type) => {
    return contract_type == TYPE_REQUEST;
}

export const isTypeReservation = (contract_type) => {
    return contract_type == TYPE_RESERVATION;
}
