/* globals moment */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SearchField from '../../common/components/SearchField';
import FromToInterval from '../../reservation/Articles/ArticlesTableEntry/FromToInterval';

import { institutionsStatisticsConfigLang } from './InstitutionsStatisticsConfig'

import { getInstitutionsStatisticsData,
    selectSearchTerm, selectFrom, selectTo, 
    setSearchTerm, setFromTo } from './store/institutionsStatisticsSlice';

const InstitutionsStatisticsHeader = () => {
    const dispatch = useDispatch();

    const searchTerm = useSelector(selectSearchTerm);
    const from = useSelector(selectFrom);
    const to = useSelector(selectTo);

    useEffect(() => {
        dispatch(getInstitutionsStatisticsData());
    }, [searchTerm, from, to]);

    const onDoSearch = (searchTerm) => {
        dispatch(setSearchTerm(searchTerm));
    }

    const onTimeIntervalChanged = (from, to) => {
        dispatch(
            setFromTo({
                from: moment(from).format("DD.MM.YYYY"),
                to: moment(to).format("DD.MM.YYYY")
        }   )
        );
    }

    return (
        <>
            <div className="header data-table-header dash-block-header flex flex__align--center">
                <div className="settings-price-header-table-header-title">{institutionsStatisticsConfigLang.data_table.title}</div>
            </div>

            <div className="header-content">
                <div className="header-content-entry-row" style={{ alignItems: "center" }}>
                    <FromToInterval article_id="0" subarticle_id="0" recurring_id="0" recurrence_pos="0" showToText={true}
                        dateOnly={true} setMin={false}
                        from={from} to={to} onChange={onTimeIntervalChanged} />
                </div>

                <div style={{ marginLeft: "auto", display: "flex" }}>
                    <SearchField onDoSearch={onDoSearch} searchTerm={searchTerm} />
                </div>
            </div>
        </>
    )
}

export default InstitutionsStatisticsHeader;
