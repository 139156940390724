import React, { useEffect } from 'react';
import SpeziFilterRow from './SpeziFilterRow';

const SpeziFilterGroup = (props) => {
    const category = props.category;
    const setSpeziFilters = props.setSpeziFilters;
    const speziFilters = props.speziFilters;
    const initialFilter = [{
        id: 0,
        name: "",
        val: ""
    }];

    useEffect(() => {
            setSpeziFilters(initialFilter);
    }, [props.category])

    const getMaxFilterId = (filters) => {
        return Math.max.apply(Math, filters.map(function(o) { return o.id; }))
    }

    const addFilter = (filter) => {
        let editFilter =  speziFilters.find(fil => fil.id == filter.id);
        let filters = [...speziFilters];
        if(editFilter) {
            if(editFilter.id == getMaxFilterId(speziFilters)) {
                filters = speziFilters.map(p => p.id === filter.id ? { ...filter } : p );
                // Add empty row
                filters = [...filters, {
                    id: getMaxFilterId(speziFilters) + 1,
                    name: "",
                    val: ""
                }];
            } else {
                filters = speziFilters.map(p => p.id === filter.id ? { ...filter } : p );
            }
        } else {
            filters = [...speziFilters, filter];
        }

        setSpeziFilters(filters);
        props.reloadArticlesTablePopup(filters);
    }

    const deleteFilter = (filter) => {
        let filtered = speziFilters.filter(fil => fil.id != filter.id );
        if(filtered.length == 0) {
            filtered = initialFilter;
        }
        setSpeziFilters(filtered);
        props.reloadArticlesTablePopup(filtered);
    }
    
    const buildSpeziFilterRow = (filter, i) => {
        return (
            <SpeziFilterRow key={category.value+"_"+filter.id} usedFilters={speziFilters} speziFilter={filter} addFilter={addFilter} deleteFilter={deleteFilter}/>
        )
    }

    return (
        <div className="spezi-filter-group">
            { speziFilters.map(buildSpeziFilterRow) }
        </div>
    )
}

export default SpeziFilterGroup;