import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'inplaceEditor',
    initialState: {
        // there can be only one inplace editor, this value is for the whole app
        showing: false
    },

    reducers: {
        setInPlaceEditorShowing: (state, action) => {
            state.showing = action.payload == undefined ? true : action.payload;
        }
    }
});

export const { setInPlaceEditorShowing } = slice.actions;

export const selectInPlaceEditorShowing = state => state.inplaceEditor.showing;

export default slice.reducer;