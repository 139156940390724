import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import '../scss/tag-item.scss'

const TagItem = (props) => {
    const cssClasses = classNames(["tag-element", props.className]);

    return (
        <div className={cssClasses}>
            <span className="tag-title">
                { props.type && <span className="tag-type">{props.type}: </span> }
                <span className="tag-name">{props.name}</span>
            </span>
            <button className="tag-close" onClick={props.onClick}></button>
        </div>
    );
}

TagItem.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,

    onClick: PropTypes.func.isRequired
}

export default TagItem;