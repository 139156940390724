import React from "react";
import "../../common/scss/common.scss";
import "./InstitutionsStatisticsApp.scss";

import { institutionsStatisticsConfigLang } from './InstitutionsStatisticsConfig'

import PageTitle from '../../common/components/PageTitle'

import InstitutionsStatisticsTableHeader from "./InstitutionsStatisticsHeader";
import InstitutionsStatisticsTable from "./InstitutionsStatisticsTable";

const InstitutionsStatisticsApp = () => {
    return (
        <div id="institutions_statistics_app_content" className="col-one-full">
            <PageTitle title={institutionsStatisticsConfigLang.page_title} />

            <div className="flex flex__col">
                <div className="data-table-block dash-block-container-sp data-table-block-extended ">
                    <InstitutionsStatisticsTableHeader />

                    <div className="table_wrapper">
                        <InstitutionsStatisticsTable />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InstitutionsStatisticsApp;