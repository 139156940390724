import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import SvgButton from "../../../common/components/SvgButton";
import ActionButton from "../../../common/components/ActionButton";

import lang from "../../../common/lang/de.json";

import { useDispatch } from 'react-redux';

import {
    setInPlaceEditorShowing,
} from '../../store/inplaceEditorSlice';

const InplaceEditPanel = (props) => {
    const dispatch = useDispatch();

    const shouldShow = props.isActive

    useEffect(() => {
        if(shouldShow) {
            dispatch(setInPlaceEditorShowing());
        }
    }, [shouldShow]);

    let cssClasses = classNames(["inplace_edit_panel", props.className]);

    // the inplace edit panel has a z-index of 3, so when showing the other elements (showing on top of it) get a higher z-index
    // this is done in the components where the panel is included
    if(shouldShow) {
        return (
            <div className={cssClasses}>
                <div style={{marginRight: "5px"}}>
                    <SvgButton onClick={() => { dispatch(setInPlaceEditorShowing(false)); props.onCancel(); }} svgRef="icon-x" textRight="" />
                </div>

                <div>
                    <ActionButton onClick={() => { dispatch(setInPlaceEditorShowing(false)); props.onOk(); }} className="small" text={lang.reservation.confirm} />
                </div>
            </div>
        );        
    } else {
        return <></>;
    }
}

InplaceEditPanel.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default InplaceEditPanel;
