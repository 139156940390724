
export const STATUS_RESERVED = 1;
export const STATUS_ENDED = 2;
export const STATUS_CANCELED = 3;
export const STATUS_ARCHIVED = 5;

export const isRequestOpen = (status) => {
    return status == STATUS_RESERVED
}

export const isReservationActive = (status) => {
    return status == STATUS_RESERVED
}

export const isReservationHandedOut = (reservationHandedOutStatus) => {
    return reservationHandedOutStatus == 1;
}

export const isReservationFullyReturned = (reservationReturnedOutStatus) => {
    return reservationReturnedOutStatus == 1;
}