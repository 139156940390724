/* global moment, quickverleih */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import DropdownSelect from '../../../common/components/DropdownSelect'
import SpeziFilterGroup from './SpeziFilterGroup';
import DropdownTreeContainer from './DropdownTreeContainer'
import FromToInterval from "../ArticlesTableEntry/FromToInterval";

import 'react-dropdown-tree-select/dist/styles.css'

import {
    setLocationId,

    selectCategories,
    selectCustomer,
    selectCustomerTenantId,
    selectArticleTenantId,
    selectStartDate,
    selectStartTime,
    selectEndDate,
    selectEndTime,
    selectFilter,
    selectSpecFilter,
    selectLocationId,
    getSpeziForArticles,
    selectLocations,
    selectCustomerLocations
} from '../../store/articlesPopupSlice';

const ArticlesPopupFilters = ({ lang }) => {
    const dispatch = useDispatch();

    const categories = useSelector(selectCategories);
    const customer = useSelector(selectCustomer);
    const customerTenantId = useSelector(selectCustomerTenantId);
    const articleTenantId = useSelector(selectArticleTenantId);
    const start_date = useSelector(selectStartDate);
    const start_time = useSelector(selectStartTime);
    const end_date = useSelector(selectEndDate);
    const end_time = useSelector(selectEndTime);
    const filter = useSelector(selectFilter);
    const specFilter = useSelector(selectSpecFilter);
    const locationId = useSelector(selectLocationId);
    const customerLocations = useSelector(selectCustomerLocations);
    const allLocationsForTenant = useSelector(selectLocations);

    const allowedForCustomer = allLocationsForTenant.filter(a => customerLocations.some(b => a.id === b.id));
    const otherLocations = allLocationsForTenant.filter(a => !customerLocations.some(b => a.id === b.id));

    const locations = [{
        label: lang.all_locations,
        value: "0"
    }, {
        label: lang.locations_for_customer,
        options: [...allowedForCustomer]
    }, {
        label: lang.other_locations,
        options: [...otherLocations]
    }];

    const initialFilter = [{
        id: 0,
        name: "",
        val: ""
    }];

    const [speziFilters, setSpeziFilters] = useState(initialFilter);
    const [category, setCategory] = useState(null);
    const [interval, setInterval] = useState({
        start_date: start_date,
        start_time: start_time,
        end_date: end_date,
        end_time: end_time
    });
    
    const shouldShowSpecsFilter = category && category.filterable;
    const selectedLocation = allLocationsForTenant.find(loc => loc.id === locationId);

    useEffect(() => {
        dispatch(getSpeziForArticles({ customerTenantId, articleTenantId, interval, filter, specFilter, locationId, category }));
        setSpeziFilters(initialFilter);
        reloadArticlesTablePopup(initialFilter);
    }, [category, locationId])

    const onChange = (currentNode, selectedNodes) => {
        setCategory(selectedNodes[0]);
    }
    const onAction = (node, action) => {
        // console.log('onAction::', action, node);
    }
    const onNodeToggle = currentNode => {
        // console.log('onNodeToggle::', currentNode);
    }

    const handleLocationChanged = (location) => {
        const newLocationId = location.id;
        dispatch(setLocationId(newLocationId));
    }

    const reloadArticlesTablePopup = (speziFiltersReceived) => {
        let categoryParam = (category && category.value ? category.value : 0)
        window.popupArticleTableReload(customer, articleTenantId, interval, filter, speziFiltersReceived, locationId, categoryParam);
    }

    const onTimeIntervalChanged = async (from, to) => {
        const fromDateTime = moment(from);
        const startDate = fromDateTime.format('DD.MM.YYYY');
        const startTime = fromDateTime.format('HH:mm');

        const toDateTime = moment(to);
        const endDate = toDateTime.format('DD.MM.YYYY');
        const endTime = toDateTime.format('HH:mm');

        let times = {
            start_date: startDate,
            start_time: startTime,
            end_date: endDate,
            end_time: endTime
        };

        quickverleih.storage.set_inteval_for_insert_article(times.start_date, times.start_time, times.end_date, times.end_time);

        setInterval(times);

        let categoryParam = (category && category.value ? category.value : 0)
        window.popupArticleTableReload(customer, articleTenantId, times, filter, speziFilters, locationId, categoryParam);
    }

    return (
        <div className="articles-popup-filters-header">
            <div className="categories-selector-spezi-group-continer">
                {locations && locations.length > 1 && <div className="locations-dropdown">
                    <div className="locations-dropdown-label">{lang.location}</div>
                    <DropdownSelect placeholder={lang.all_locations} value={selectedLocation} options={locations} onChange={handleLocationChanged} />
                </div>}
                <DropdownTreeContainer className="category-selector-tree" data={categories} texts={{ placeholder: lang.filter_on_category }} keepTreeOnSearch={true} keepChildrenOnSearch={true} onChange={onChange} onAction={onAction} onNodeToggle={onNodeToggle} mode="radioSelect" />
                {shouldShowSpecsFilter &&
                    <SpeziFilterGroup category={category} setSpeziFilters={setSpeziFilters} speziFilters={speziFilters} reloadArticlesTablePopup={reloadArticlesTablePopup} />
                }
            </div>

            <div className="articles-popup-filters-header-from-to">
                <FromToInterval article_id="0" subarticle_id="0" recurring_id="0" recurrence_pos="0"
                    from={start_date + " " + start_time} to={end_date + " " + end_time} onChange={onTimeIntervalChanged} />
            </div>
        </div>
    )
}

ArticlesPopupFilters.propTypes = {
    lang: PropTypes.object.isRequired,
}

export default ArticlesPopupFilters;