import { client } from '../../common/api/client';

const settingsBaseUrl = "/includes/einstellung/";
const baseApiUrl = settingsBaseUrl + "api/index.php?method=";

function getApiUrl(action) {
    return baseApiUrl + action;
}
class InstitutionsApi {

    static getSettings() {
        return client.post(getApiUrl("getInstitutionsSettings"));
    }

    static setSettings(enableInstitutions) {
        return client.post(getApiUrl("updateInstitutionsSettings"), {
            enableInstitutions
        });
    }

    static getDownloadUrlForTemplate() {
        return settingsBaseUrl + "downloadImportInstitutionsTemplate.php";
    }

    static uploadInstitutionsImportFile(file) {
        return client.fileUpload(getApiUrl("uploadInstitutionsImportFile"), "import_file", file);
    }

    static getInstitutionsData(searchTerm, rowsOnPage, pageNumber) {
        return client.post(getApiUrl("getInstitutionsData"), {
            searchTerm,
            rowsOnPage,
            pageNumber
        });
    }

    static addInstitution(institutionData) {
        return client.post(getApiUrl("addInstitution"), institutionData);
    }    
}

export default InstitutionsApi;
