/* global qvapp */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import DropdownSelect from '../../common/components/DropdownSelect'

import { rentalHistoryLang, filterByColumns } from './RentalHistoryConfig'
import AutoSuggestPopup from '../common/components/AutoSuggestPopup';
import TagsList from '../../common/components/TagsList';


import { 
    setFilters,
    getRentalHistoryData,
    getRentalHistoryTotalRows
 } from "./store/rentalHistorySlice";

const RentalHistoryDataTableHeader = (props) => {
    const dispatch = useDispatch();
    const headerLang = rentalHistoryLang.header;

    const [tags, setTags] = useState([]);
    const [filter, setFilter] = useState(null);
    const [showAutosuggestPopup, setShowAutosuggestPopup] = useState(false);

    useEffect(() => {
        dispatch(setFilters(tags));
        dispatch(getRentalHistoryData());
        dispatch(getRentalHistoryTotalRows());
    }, [tags])

    const handleFilterableColumnChange = (filterColumn) => {
        const filterBy = {
            column: filterColumn.value,
            value: ""
        }
        setFilter(filterBy);
        setShowAutosuggestPopup(true);
    }

    const onSuggestionSelected = (suggestion) => {
        let newTag = {...filter, 'value': suggestion};
        setTags([...tags, newTag]);
        setFilter(null);
        setShowAutosuggestPopup(false);
    }

    const onCancelClicked = () => {
        setFilter(null);
        setShowAutosuggestPopup(false);
    }

    const onRemoveTag = tag => {
        setTags(tags.filter(filter => !(filter.column === tag.column && filter.value.id === tag.value.id)));
    }

    return (
        <div className="header data-table-header dash-block-header flex flex__align--center">
            <div className="rental-history-header-table-header-title">{rentalHistoryLang.data_table.title}</div>
            <div className="table-header-content-right">
                <div className="table-header-content-right-item">
                    <TagsList lang={headerLang.tags_categories} tags={tags} onClick={onRemoveTag} />
                    <DropdownSelect placeholder={headerLang.filterable_column_dropdown_placeholder} value={filter} defaultValue={null} options={filterByColumns} onChange={handleFilterableColumnChange} />
                    {showAutosuggestPopup && filter &&
                        <AutoSuggestPopup lang={headerLang.autosuggest} showCloseIcon={false} filter={filter} title={filter.column == 'customer' ? headerLang.autosuggest.titles["search_by"] + qvapp.i18n.t("label_lender") : headerLang.autosuggest.titles[filter.column]} showOKButton={false} okButtonLabel={headerLang.autosuggest.on_button} okButtonClass="primary-button"
                            onOkClicked={onSuggestionSelected} onSuggestionSelected={onSuggestionSelected} onCancelClicked={onCancelClicked}>
                            <br />
                        </AutoSuggestPopup>
                    }
                </div>
            </div>
        </div>
    )
}

export default RentalHistoryDataTableHeader;