import React from 'react';
import he from 'he';

import lang from "../../common/lang/de.json";

export const shopSearchTermsStatisticsConfigLang = lang.shop_search_terms_statistics;

export const shopSearchTermsStatisticsTableColumns = [
    {
        name: shopSearchTermsStatisticsConfigLang.data_table.columns_names.term,
        selector: row => (<div>{he.decode(row.search_term)}</div>),
        width: "300px",
        minWidth: "300px",
        sortable: false,
    },
    {
        name: shopSearchTermsStatisticsConfigLang.data_table.columns_names.term_count,
        cell: row => row.term_count,
        width: "100px",
        minWidth: "100px",
        sortable: false,
    },
    {
        name: shopSearchTermsStatisticsConfigLang.data_table.columns_names.total_found_articles_count,
        cell: row => row.total_found_articles_count,
        minWidth: "200px",
        sortable: false,
    }
];
