/* global contract_type:writable, moment */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import RentalHistoryApi from "../api/RentalHistoryApi";

const initialState = {
    status: 'idle',
    error: null,

    pageNumber: 1,
    rowsOnPage: 20,
    totalRows: 0,

    filters: [],
    sortField: "vertragsnr",
    sortDirection: "desc",

    rental_history_data: []
};

export const getRentalHistoryData = createAsyncThunk(
    'rentalHistory/getRentalHistoryData',
    async (_noParams, { getState }) => {
        const rowsOnPage = getState().rental_history.rowsOnPage;
        const pageNumber = getState().rental_history.pageNumber;

        const sortField = getState().rental_history.sortField;
        const sortDirection = getState().rental_history.sortDirection;
        
        const filters = getState().rental_history.filters;
        const article_numbers = filters.reduce((prev, curr) => { if (curr.column === 'gnr') { return [...prev, curr.value]} else return prev}, []);
        const customer_names  = filters.reduce((prev, curr) => { if (curr.column === 'customer') { return [...prev, curr.value]} else return prev}, []);

        const response = await RentalHistoryApi.getRentalHistoryData(article_numbers, customer_names, rowsOnPage, pageNumber, sortField, sortDirection);
        return response.rental_history_data;
    }
)

export const getRentalHistoryTotalRows = createAsyncThunk(
    'rentalHistory/getRentalHistoryTotalRows',
    async (_noParams, { getState }) => {
        const filters = getState().rental_history.filters;
        const article_numbers = filters.reduce((prev, curr) => { if (curr.column === 'gnr') { return [...prev, curr.value]} else return prev}, []);
        const customer_names  = filters.reduce((prev, curr) => { if (curr.column === 'customer') { return [...prev, curr.value]} else return prev}, []);

        const response = await RentalHistoryApi.getRentalHistoryTotalRows(article_numbers, customer_names);
        return response;
    }
)

export const slice = createSlice({
    name: 'rental_history',
    initialState,

    reducers: {
        setRowsOnPage: (state, action) => {
            state.rowsOnPage = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setSortField: (state, action) => {
            state.sortField = action.payload;
        },
        setSortDirection: (state, action) => {
            state.sortDirection = action.payload;
        }
    },

    extraReducers: {
        [getRentalHistoryData.pending]: (state) => {
            state.status = 'loading';
        },
        [getRentalHistoryData.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.rental_history_data = action.payload;
        },
        [getRentalHistoryData.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },

        [getRentalHistoryTotalRows.pending]: (state) => {
            state.status = 'loading';
        },
        [getRentalHistoryTotalRows.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.totalRows = parseInt(action.payload.rental_history_total_rows);
        },
        [getRentalHistoryTotalRows.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        }
    }
});

export const { setRowsOnPage, setPageNumber, setFilters, setSortField, setSortDirection } = slice.actions;

export const selectPageNumber = state => state.rental_history.pageNumber;
export const selectRowsOnPage = state => state.rental_history.rowsOnPage;
export const selectRentalHistoryData = state => state.rental_history.rental_history_data;
export const selectTotalRows = state => state.rental_history.totalRows;

export const selectIsLoading = state => state.rental_history.status === "loading";

export default slice.reducer;