/* globals moment */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import InstitutionsStatisticsApi from "../api/InstitutionsStatisticsApi";

import { convertDateForServer } from "../../../common/utils";

import lang from "../../../common/lang/de.json";

const initialState = {
    status: 'idle',
    error: null,

    searchTerm: "",
    from: moment().minute(0).subtract(7, 'days').format("DD.MM.YYYY"),
    to: moment().minute(0).format("DD.MM.YYYY"),

    institutionsStatisticsData: [],
};


export const getInstitutionsStatisticsData = createAsyncThunk(
    'institutions_statistics/get_cost_centers_data',
    async (_noParams, { getState }) => {
        
        const searchTerm = getState().institutions_statistics.searchTerm;
        const from = getState().institutions_statistics.from;
        const to = getState().institutions_statistics.to;
        
        const response = await InstitutionsStatisticsApi.getInstitutionsStatisticsData(searchTerm,
            convertDateForServer(from), convertDateForServer(to));

        return response;
    }
)

export const slice = createSlice({
    name: 'institutions_statistics',
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setFromTo: (state, action) => {
            state.from = action.payload.from;
            state.to = action.payload.to;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInstitutionsStatisticsData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getInstitutionsStatisticsData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.institutionsStatisticsData = action.payload.institutions_statistics_data;
            })
            .addCase(getInstitutionsStatisticsData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    },
});

export const selectIsLoading = state => state.institutions_statistics.status === "loading";
export const selectSearchTerm = state => state.institutions_statistics.searchTerm;
export const selectFrom = state => state.institutions_statistics.from;
export const selectTo = state => state.institutions_statistics.to;
export const selectInstitutionsStatisticsData = state => state.institutions_statistics.institutionsStatisticsData;

export const { setSearchTerm, setFromTo } = slice.actions;

export default slice.reducer;