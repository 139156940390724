import lang from "../../common/lang/de.json";

export const costCentersStatisticsConfigLang = lang.cost_centers_statistics;

export const costCentersStatisticsTableColumns = [
    {
        name: costCentersStatisticsConfigLang.data_table.columns_names.number,
        selector: row => row.number,
        width: "200px",
        minWidth: "200px",
        sortable: false,
    },
    {
        name: costCentersStatisticsConfigLang.data_table.columns_names.costs,
        selector: row => (row.costs + " €"),
        minWidth: "500px",
        sortable: false,
        right: true
    }
];
