import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../common/scss/common.scss";
import "./CostCentersStatisticsApp.scss";

import {
    getCostCentersStatisticsData,
    selectSearchTerm, selectFrom, selectTo
} from "./store/costCentersStatisticsSlice";

import { costCentersStatisticsConfigLang } from './CostCentersStatisticsConfig'

import PageTitle from '../../common/components/PageTitle'

import CostCentersStatisticsTableHeader from "./CostCentersStatisticsHeader";
import CostCentersStatisticsTable from "./CostCentersStatisticsTable";

const CostCentersStatisticsApp = () => {
    const dispatch = useDispatch();

    const searchTerm = useSelector(selectSearchTerm);
    const from = useSelector(selectFrom);
    const to = useSelector(selectTo);

    useEffect(() => {
        dispatch(getCostCentersStatisticsData());
    }, [searchTerm, from, to]);

    return (
        <div id="cost_centers_statistics_app_content" className="col-one-full">
            <PageTitle title={costCentersStatisticsConfigLang.page_title} />

            <div className="flex flex__col">
                <div className="data-table-block dash-block-container-sp data-table-block-extended ">
                    <CostCentersStatisticsTableHeader />

                    <div className="table_wrapper">
                        <CostCentersStatisticsTable />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CostCentersStatisticsApp;