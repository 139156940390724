import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

import lang from "../../../common/lang/de.json";
import SvgButton from '../../../common/components/SvgButton';
import DropdownSelect from '../../../common/components/DropdownSelect';

import {
    selectSpezi
} from '../../store/articlesPopupSlice';

const SpeziFilterRow = (props) => {

    const valueAll = "value_all"; // should match the value from SpecsFilter.php

    const attributes = useSelector(selectSpezi);
    const defaultValueForAll = {
        label: "Alle",
        value: valueAll
    };

    const initialAttributeOptions = [
        ...Object.entries(attributes).map(([attribute, values]) => {
            return {
                label: attribute,
                value: attribute
            };
        })
    ];

    const [speziFilter, setSpeziFilter] = useState(props.speziFilter || null);
    const [showValuesFor, setShowValuesFor] = useState((speziFilter && speziFilter.name && speziFilter.name != "") ? speziFilter.name : false);
    const [attributeValueOptions, setAttributeValueOptions] = useState([defaultValueForAll]);
    const [attributeOptions, setAttributeOptions] = useState(initialAttributeOptions);

    useEffect(() => {
        const newAttributeOptions = [
            ...Object.entries(attributes).map(([attribute, values]) => {
                return {
                    label: attribute,
                    value: attribute
                };
            })
        ];
        setAttributeOptions(newAttributeOptions);
    }, [attributes])

    useEffect(() => {
        let disabledOptions = attributeOptions;
        props.usedFilters.map(filter => {
            disabledOptions = disabledOptions.map(option => {
                if (option.value === filter.name) {
                    option.isDisabled = true;
                }
                return option;
            });
        });
        setAttributeOptions(disabledOptions);
    }, [props.usedFilters])

    useEffect(() => {
        setSpeziFilter(props.speziFilter);
        setShowValuesFor((speziFilter && speziFilter.name && speziFilter.name != "") ? speziFilter.name : false);
        if (showValuesFor) {
            setAttributeValueOptions(getAttributeValuesOptionsForAttribute());
        }
    }, [props.speziFilter])

    useEffect(() => {
        if (showValuesFor) {
            setAttributeValueOptions(getAttributeValuesOptionsForAttribute());
        }
    }, [showValuesFor])

    const getAttributeValuesOptionsForAttribute = () => {
        return [
            defaultValueForAll,
            ...attributes[showValuesFor]
                .filter(value => value.trim() !== "")
                .map(value => {
                    return {
                        label: value,
                        value: value
                    };
                })
        ];
    }

    const handleAttributeChange = (option) => {
        if (option.value === valueAll) {
            option.value = false;
        }
        setShowValuesFor(option.value);
        const filter = {
            ...speziFilter,
            name: option.value,
            val: valueAll
        }
        setSpeziFilter(filter);
        props.addFilter(filter);
    }

    const handleAttributeValueChange = (option) => {
        const filter = {
            ...speziFilter,
            name: showValuesFor,
            val: option.value
        }
        setSpeziFilter(filter);
        props.addFilter(filter);
    }

    const deleteFilter = () => {
        props.deleteFilter(speziFilter);
    }

    const formatDropdownOptionLabel = (data) => {
        return (
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.label) }} />
        );
    }

    return (
        <div className="spezi-filter-row-continer">
            <div className="form-control">
                <DropdownSelect placeholder="Filter nach Eigenschaften" defaultValue={null} className='react-select-container' classNamePrefix="react-select"
                    formatOptionLabel={formatDropdownOptionLabel} options={attributeOptions} onChange={handleAttributeChange}
                    value={attributeOptions.find(option => speziFilter && speziFilter.name && option.value == speziFilter.name) || null}
                />
            </div>
            {showValuesFor && attributes && attributes[showValuesFor] &&
                <div className="form-control" >
                    <DropdownSelect defaultValue={null} className='react-select-container' classNamePrefix="react-select" options={attributeValueOptions}
                        formatOptionLabel={formatDropdownOptionLabel} onChange={handleAttributeValueChange}
                        value={attributeValueOptions.find(option => speziFilter && speziFilter.name && option.value == speziFilter.val) || defaultValueForAll}
                    />
                </div>
            }
            {false && showValuesFor && attributes && attributes[showValuesFor] && speziFilter.name != "" && speziFilter.val != "" &&
                <SvgButton svgRef="icon-knob" textRight={lang.reservation.change_customer} onClick={deleteFilter} />
            }
        </div>
    )
}

export default SpeziFilterRow;