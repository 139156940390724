// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

// this is a simple json client use a library for more complex operations

function encodeFileData(fileKey, file, body = {}) {
    var formData = new FormData();
    formData.append(fileKey, file);

    Object.keys(body).forEach(key => {
        formData.append(key, body[key]);
    });

    return formData;
}


function encodeFormBody(data) {
    var formBody = [];
    for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);

        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");

    return formBody;
}

export async function client(endpoint, { fileKey, file, body, ...customConfig } = {}) {
    let headers;
    const doFormDataPost = !!customConfig.formDataPost;
    const doFileDataPost = !!customConfig.fileDataPost;
    delete customConfig.formDataPost;
    delete customConfig.fileDataPost;

    if (doFormDataPost) {
        headers = { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' };
    } else if (doFileDataPost) {
        // leave headers empty for file upload, this will make the fetch call set the headers automatically, otherwise there is an error
        headers = {}; // { 'Content-Type': 'multipart/form-data' };
    } else {
        headers = { 'Content-Type': 'application/json' };
    }

    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };

    if (doFormDataPost) {
        config.body = encodeFormBody(body);
    } else if (doFileDataPost) {
        config.body = encodeFileData(fileKey, file, body);
    } else {
        config.body = JSON.stringify(body);
    }

    let data;
    try {
        const response = await window.fetch(endpoint, config);
        data = await response.json();

        if (response.ok) {
            return data;
        }

        throw new Error(response.statusText);
    } catch (err) {
        return Promise.reject(err.message ? err.message : data);
    }
}

client.get = function (endpoint, customConfig = {}) {
    return client(endpoint, { ...customConfig, method: 'GET' });
};

client.post = function (endpoint, body, customConfig = {}) {
    return client(endpoint, { ...customConfig, body });
};

client.fileUpload = function (
    endpoint,
    fileKey,
    file,
    body
) {
    if(!body) {
        body = {};
    }

    return client(endpoint, {
        body,
        fileKey,
        file,
        fileDataPost: true,
    });
};
