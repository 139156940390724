/* global moment */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import classNames from "classnames";

import "./../scss/popup.scss";

import lang from "../lang/de.json";

import Popup from "../../common/components/Popup";

const EnterDatePopup = (props) => {
    const now = moment();

    const [dateForAction, setDateForAction] = useState(now.format('DD.MM.YYYY'));
    const [dateForActionIsValid, setDateForActionIsValid] = useState(true);

    const changeCurrentDay = (event) => {
        const dateValue = event.target.value;

        setDateForActionIsValid(moment(dateValue, 'DD.MM.YYYY', true).isValid())
        setDateForAction(dateValue);
    }

    const onOk = () => {
        if(dateForActionIsValid) {
            const enteredDate = moment(dateForAction, 'DD.MM.YYYY', true);

            props.onOk(enteredDate.format('YYYY-MM-DD'));
        }
    }

    const cssClassesForDateForAction = classNames("day", {redBorder: !dateForActionIsValid});

    return (
        <Popup title={props.title} showButtons={true} onOkClicked={onOk} onCancelClicked={props.onCancel} className="center_content">
            {lang.reservation.enter_date}
            <div className="info">
                <InputMask value={dateForAction} onChange={changeCurrentDay} className={cssClassesForDateForAction} mask='99.99.9999' style={{width: '90px'}} />
            </div>
        </Popup>
    );
}

EnterDatePopup.propTypes = {
    title: PropTypes.string.isRequired,
    onOk: PropTypes.func,
    onCancel: PropTypes.func.isRequired
}

export default EnterDatePopup;