
import { STATUS_HANDEDOUT, STATUS_RETURNED } from "../ArticleStatus";

export const getMaxAllowedHandedoutCount = (handouts, rentedCount, excludeHandout = null) => {
    let alreadyHandedOutCount = 0;

    if(handouts) {
        for(let articleHandout of handouts) {
            if(excludeHandout === null || articleHandout.id !== excludeHandout.id) {
                alreadyHandedOutCount += parseInt(articleHandout.quantity);
            }
        }
    }

    return parseInt(rentedCount) - alreadyHandedOutCount;
}

export const getHandedoutCount = (handouts, excludeHandout = null) => {
    let alreadyHandedOutCount = 0;

    if(handouts) {
        for(let articleHandout of handouts) {
            if(excludeHandout === null || articleHandout.id !== excludeHandout.id) {
                alreadyHandedOutCount += parseInt(articleHandout.quantity);
            }
        }
    }

    return alreadyHandedOutCount;
}

export const getMaxAllowedReturnCount = (returns, handedOutCount, excludeReturn = null) => {
    let alreadyReturnedCount = 0;

    if(returns) {
        for(let articleReturn of returns) {
            if(excludeReturn === null || articleReturn.id !== excludeReturn.id) {
                alreadyReturnedCount += parseInt(articleReturn.quantity);
            }
        }
    }

    return parseInt(handedOutCount) - alreadyReturnedCount;
}

export const getReturnedCount = (returns) => {
    let alreadyReturnedCount = 0;

    if(returns) {
        for(let articleReturn of returns) {
            alreadyReturnedCount += parseInt(articleReturn.quantity);
        }
    }

    return alreadyReturnedCount;
}

export const areAllArticlesHandedout = (articles) => {
    let allHandedout = true;

    for(let article of articles) {
        // if the articles has subarticles check the status of all subarticles
        if(article.has_subarticles) {
            const allSubarticlesHandedout = areAllArticlesHandedout(article.subarticles);
            if(!allSubarticlesHandedout) {
                allHandedout = false;
                break;    
            }

        }
        // returned articles are by default handedout
        else if(article.in_contract_status !== STATUS_HANDEDOUT && article.in_contract_status !== STATUS_RETURNED) {
            allHandedout = false;
            break;
        }
    }

    return allHandedout;
}