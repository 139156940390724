import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import InstitutionsStatisticsApp from './InstitutionsStatisticsApp';

import institutionsStatisticsReducer from './store/institutionsStatisticsSlice';

// the interface between js app and react app
window.quickverleih.institutions_statistics_app = (function() {

    function init() {

        let institutionsStatisticsStore = configureStore({
            reducer: {
                institutions_statistics: institutionsStatisticsReducer,
            }
        })

        ReactDOM.render(
            <Provider store={institutionsStatisticsStore}>
                <InstitutionsStatisticsApp />
            </Provider>,
            document.getElementById("institutions_statistics_app")
        );
    }

    return {
        init
    };
})();